<template>
    <el-carousel  ref="carouse" :interval="5000" indicator-position="none" height="2.8rem">
        <el-carousel-item v-for="item in list" :key="item.id">
            <div 
                class="organize-page">
                <div class="top">
                    <div class="organize-bigBox">
                        <el-image
                            class="head-img"
                            :src="item.image_upload1728452703168&&item.image_upload1728452703168.length>0?item.image_upload1728452703168[0].url:require('../../src/assets/img/tou.png')"
                            fit="contain"
                        >
                        </el-image>
                        <el-descriptions title="" :column="2" size="medium" :colon="false">
                            <el-descriptions-item label="姓名：">{{item.input1728452637879||''}}</el-descriptions-item>
                            <el-descriptions-item label="出生日期：">{{item.input1728452671719||''}}</el-descriptions-item>
                            <el-descriptions-item label="性别：">{{item.select1728452647635label||''}}</el-descriptions-item>
                            <el-descriptions-item label="民族：">{{item.input1728452684968||''}}</el-descriptions-item>
                            <el-descriptions-item label="学历：">{{item.input1728452662422||''}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <!-- <div class="title">介绍</div> -->
                    <div class="content">介绍：{{item.textarea1728452856528||'暂无'}}</div>
                </div>
            </div>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import {getVillage} from '../../src/api/api'
export default {
    name:"organize",
    data(){
        return{
            list:[],
        }
    },
    created () {
        this.getData()
    },
    mounted() {
    },
    destroyed(){
    },
    methods: { 
        getData(){
            getVillage({
                "formKey": "ilE3nxiC",
                "queryId": "189",
                "queryParams": {
                    "select1728452623691label": "社区"
                },
                "size": 100,
                "current": 0
            }).then(res=>{
                if(res.data.code==200){
                    this.list=res.data.data.rows
                }
            })
        }
    }

}
</script>

<style scoped>
::v-deep .el-descriptions__body{
    /* background-color: transparent; */
    padding:30px 20px 0;
    font-size: 18px;
    color:#000;
    height:100%;
}
.organize-page{
    width: 100%;
    height:100%;
    /* background: #F7F8FA; */
    /* background: #FFF; */
    border-radius: 4px;
    overflow: scroll;
    padding:20px;
}
.title{
    font-size: 18px;
    font-weight: bold;
    /* color: #191919; */
    color:#fff;
    padding:10px 0;
}
.top{
    padding:0 15px;
    margin-bottom: 20px;
}
.organize-bigBox{
    width:100%;
    display: flex;
    /* height:250px; */

}
.head-img{
    width:150px;
    height:160px;
    flex-shrink: 0;
    margin-right: 10px;
}
.content{
    margin-top:10px;
    font-size: 18px;
    /* color:#191919; */
    color:#fff;
    /* text-indent: 2em; */
}
</style>