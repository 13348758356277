<template>
    <div class="com-page">
        <right2></right2>
    </div>
</template>

<script>
    import right2 from '../src/components/right2'
    export default{
        name:"right2Page",
        components: { 
            right2
        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>