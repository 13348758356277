export default class ArrayPower{

    // ⏹分组
    static groupBy = (arr, key) => arr.reduce((acc, item) => ((acc[item[key]] = [...(acc[item[key]] || []), item]), acc), {});
    
    // ⏹过滤
    static reducedFilter(data, keys, fn) {
        return data.filter(fn).map(el =>
            keys.reduce((acc, key) => {
                acc[key] = el[key];
                return acc;
            }, {})
        );
    }
  
    // ⏹并集
    /*
        获取多个数组的并集
            ...arr 相当于将所有的参数都放到一个数组里面
            相当于let arr = [[1, 2], [2, 3], [3], [3, 4], [4, 5, 1]]
            然后再调用arr.flat()方法将数组展平为一维数组[1, 2, 2, 3, 3, 3, 4, 4, 5, 1]
            ⭕.flat()默认只拉平嵌套一层的数组,如果想拉平任意层数的数组的话,添加参数Infinity
            然后使用Set集合进行去重
    */
    static unionArray = (...arr) => [...new Set(arr.flat(Infinity))];
  
    // ⏹获取指定元素的下标
    static getIndexBy = (arr, value) => arr.map((v, i) => v === value ? i : false).filter(Boolean);
  }