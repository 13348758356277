<template>
    <div class="h560">
        <div class="bianmin mt30" v-if="name=='便民管家'">
            <div v-for="item in list" :key="item.id" class="flex mb10">
                <img
                    class="bianmin-img"
                    :src="item.image_upload1689141112322[0].url"
                />
                <div class="right-box">
                    <div class="right-box-text">
                         <div>{{item.select1689142020082label||''}}</div>
                        <div>{{item.select1689142020082||''}}</div>
                    </div>
                    <img src="../../src/assets/img/line.png" class="img">
                </div>    
            </div>
        </div>
        <div v-if="name=='全科网格员职能'" class="zhineng">
            <div v-for="(item,i) in roleList" :key="i">
                <div class="mb40">
                    <span class="title title-padding">{{item.title}}</span>
                </div>
                <div class="text2e mb40">
                    {{item.content}}
                </div>
            </div>
        </div>
        <div v-if="name=='网格员管理制度'" class="kaohe">
            <div class="textIndent mb28">
                为深入推进党建引领微网实格治理改革，全面构建党建引领下社区辖区网格化治理的新格局，切实建设好、管理好、发展好网格员队伍，结合工作实际，制定本制度。
            </div>
            <div class="title mb28">日常考核</div>
            <div class="mb28" style="display:flex">
                <div class="zhidu-box" style="width:47.5%;margin-right:5%;height: auto;">
                    <div class="flex mb16">
                        <div class="point"></div>
                        <div class="section">基本工作要求</div>
                    </div>
                    <div class="text">网格员是社区联系人民群众的重要纽带，在社区党委、居委的领导下，以联系服务群众为宗旨，体现基层治理自我管理、自我服务。</div>
                </div>
                <div class="zhidu-box" style="width:47.5%;height: auto;">
                     <div class="flex mb16">
                        <div class="point"></div>
                        <div class="section">主要职责任务</div>
                    </div>
                    <div class="text">社会保障、综合治理、应急管理等各级职能部门下派的工作，网格内安全隐患排查、矛盾纠纷化解、宣传政策法规、实现“网格事网格管”，确保“小事不出网格，大事不出社区“。</div>
                </div>
            </div>
            <div class="mb28 zhidu-box">
                 <div class="flex mb16">
                        <div class="point"></div>
                        <div class="section">基本工作考核</div>
                    </div>
                <div class="text">
                    1、日常考核管理（10分）；事件处理上报（20分）；服务管理（20分）；宣传业务考核（10分）。<br/>
                    2、有效参与事件处理上报加分（20分）；积极参与各类社区组织的活动（20分）。</div>
            </div>
            <div class="title mb28">淘汰机制</div>
            <div class="zhidu-box">
                 <div class="flex mb16">
                        <div class="point"></div>
                        <div class="section">考核遵循“务实、高效”、“公开、公平、公正”</div>
                    </div>
                <div class="text">
                    1、每月底考核优秀（80分），<br/>
                    2、月考核合格（60分及以上）；<br/>
                    3、不合格核得分60分以下的，<br/>
                </div>
            </div>
        </div>
        <div v-if="name=='网格员考核制度'" style="background-color: #F7F8FA;display:flex;justify-content: center;
    align-content: center;padding:10px">
            <el-image
              style="width:90%; height:100%;"
              :src="require('../../src/assets/img/kaohe.png')"
              fit="cover"
            >
            </el-image>
        </div>
    </div>
</template>
<script>
import {getVillage} from '../../src/api/api'
export default {
        name:"textPage",
         props: {
			name:{
                type:String,
                default:''
            }
		},
        data() {
            return {
                list:[],
                roleList:[{
                    title:'一、信息摸排',
                    content:'负责对网格的“人、情、地、事、物、组织”等基本情况全面走访、了解、登记、核对，全方位掌握基础信息，并录入社会管理信息平台，做到底数清、情况明、信息难。'
                },{
                    title:'二、政策宣传',
                    content:'积极向居民群众宣传政策法规知识、工作开展情況等，提高居民对社区服务管理的知晓率、参与率和满意率。'
                },{
                    title:'三、便民服务',
                    content:'履行社会治安、社会保障、劳动就业、计划生育、城市管理、安全生产产等服务管理职责。'
                },{
                    title:'四、日常巡查',
                    content:'开展日常巡查工作，保质保量的完成一日一巡，全面准确掌握网格动态。'
                },{
                    title:'五、化解矛盾',
                    content:'及时排查、化解网格内发生的矛盾纠纷和不稳定因素，本人无法化解的，上报社区予以化解。原则上辖区内一般问题在2个工作日内要予以回复，复杂疑难问题需由上级解决或需要上级协调解决的，在5个工作日予以回复，对短期内不能解決的问题要及时说明原因。'
                },{
                    title:'六、处理隐患',
                    content:'及时排查网格内治安、安全等隐患，加强治安巡查，发现治安问题及时解決或上报社区予以解决。'
                },{
                     title:'七、应急处置',
                    content:'遇到突发事件时，应第一时间到达现场，紧急处理，立即上报社区和街道，并现场跟踪，配合上级组织稳控局面、妥善处理。'
                },{
                    title:'八、开展活动',
                    content:'积极配合社区组织居民开展积极健康的文体娱乐活动和社会公益行动。'
                },{
                     title:'九、其他工作',
                    content:'完成街道、社区交办的其他工作。'
                }]
            }
        },
        created(){
            if(this.name=='便民管家'){
                this.getData()
            }
            
        },
        mounted() {
            
        },
        destroyed(){
 
        },
        methods: {
            getData(){
                getVillage({
                    formKey: "ucN3ahF6",
                    queryId: "108",
                    queryParams: {
                    select1689140601082label: "蟠龙社区"
                    },
                    size: 100,
                    current: 0
                }).then(res=>{
                    this.list=res.data.data.rows
                    // console.log(res,'res')
                })
            }
        }
}
</script>

<style scoped>
.bianmin-img{
    width:67px;
    height:67px;
    margin-right:10px;
}
.right-box{
    width:170px;
    position:relative;
    left:0;
}
.right-box-text{
    padding:5.5px 2px 5.5px 20px;
}
.img{
    width:100%;
    position: absolute;
    bottom:0
}
.flex{
    display: flex;
    align-items: center;
    width:50%;
    justify-content: center;
}
.h560{
    height:560px;
    overflow: scroll;
}
.box-content .bianmin{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    /* font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: bold;
    color: #ffffff; */
    display: flex;
    flex-wrap: wrap;
}
.mb10{
margin-bottom: 10px;
}
.zhineng{
    font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    color: #ffffff;
    line-height:30px;
}
.kaohe{
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    color: #ffffff;
    line-height:30px; 
}
.mt30{
    margin-top:30px;
}
.textIndent{
    text-indent:2em;
}
.margin-left{
    margin-left: 2em;
}
.title-padding{
    padding:0 29.5px 0 26px;
}
.title{
    position:relative; 
    font-size: 20px;
    font-weight: bold;
    color: #f6f9fe;
}
.mb40{
    margin-bottom: 40px;
}
.mb28{
   margin-bottom: 28px; 
}
.mb16{
    margin-bottom: 16px; 
}
.text2e{
    text-indent: 2em;
}
.title-padding:after{
    content:"";
    position:absolute;
    bottom:-15.5px;
    left:0;
    width: 100%;
    height: 8px;
    background: url(../../src/assets/img/border-img.png) no-repeat center;
    background-size: contain;
}
.zhidu-box{
    background-image:url(../../src/assets/img/kaohe-bg.png);
    background-repeat:"no-repeat";
    background-size: "100% 100%";
    padding:15px 20px;
}
.zhidu-box .point{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0177ff;
    border: 1px solid #f3f4f4;
    box-shadow: 0px 3.5px 13.5px 0px rgba(0,24,74,0.54); 
    margin-right:8px;
}
.zhidu-box .section{
    font-size: 18px;
    font-weight: bold;
    color: #152230;
}
.text{
    font-size: 16px;
    color: #222222;
    padding-left:12px;
}
</style>