<template>
    <div class="com-page">
        <right4></right4>
    </div>
</template>

<script>
    import right4 from '../src/components/right4'
    export default{
        name:"right4Page",
        components: { 
            right4
        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>