<template>
    <div class="box" 
         v-loading="loading"  
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)"
    >
       <div class="topic">
           <div class="title">
                {{data.originalData&&data.originalData.cascader1669709828023label?data.originalData.cascader1669709828023label.join('-'):''}}
            </div>
           <img  src="../../src/assets/img/border.png" class="border"/>
        </div> 
       <div class="box-content">
            <div style="overflow:scroll;height:100%;">
                <div class="evement-box">
                    <span class="evement-title">问题/事件描述</span>
                </div>
                <div class="describe">
                   {{data.originalData&&data.originalData.textarea1669626440853?data.originalData.textarea1669626440853:''}}   
                </div>
                <video 
                    :src="data.originalData.upload1690770776064[0].url" 
                    class="video1" 
                    v-if="data.originalData&&data.originalData.upload1690770776064&&data.originalData.upload1690770776064.length>0" 
                    controls
                ></video>
                <div class="pl">
                    <el-timeline>
                    <el-timeline-item placement="top" color="#146BA1" hide-timestamp v-if="data.originalData">
                        <div class="timeline">
                            <div class="timeline-left">
                            <div>上报时间</div>
                            <div>{{data.createTime||''}}</div>
                            </div>
                            <div class="timeline-right">
                                <div>姓名：{{data.originalData&&data.originalData.input1669709598535?data.originalData.input1669709598535:''}}</div>
                            <div> 电话：{{mobile(data.originalData&&data.originalData.input1669627520033?data.originalData.input1669627520033:'')}}</div>
                            <div>地址：{{data.originalData&&data.originalData.input1669626526806?data.originalData.input1669626526806:''}}</div>
                            <div>
                                <el-image
                                    class="img"
                                    fit="cover" 
                                    v-for="(item,index) in imgArr" :key="index"
                                    :src="item.url"
                                    :preview-src-list="imgArr.map(item=>item.url)"
                                >
                                </el-image>
                            </div>
                            <span class="status">{{data.gridReportStatusEntity?'已分配':'待分配'}}</span>
                            </div>
                        </div>
                    </el-timeline-item>
                     <el-timeline-item placement="top" color="#146BA1" hide-timestamp v-if="data.gridReportStatusEntity">
                        <div class="timeline">
                            <div class="timeline-left">
                                <div>网格长分配</div>
                                <div>{{data.gridReportStatusEntity.createTime||''}}</div>
                            </div>
                            <div class="timeline-right">
                                <div>网格员：{{data.gridReportStatusEntity&&data.gridReportStatusEntity.gridName?data.gridReportStatusEntity.gridName:''}}</div>
                                <div> 电话：{{mobile(data.gridReportStatusEntity&&data.gridReportStatusEntity.gridPhone?data.gridReportStatusEntity.gridPhone:'')}}</div>
                                <span class="status">{{data.gridReportStatusEntity&&data.gridReportStatusEntity.gridReplay?'已处理':'待处理'}}</span>
                            </div>
                        </div>
                    </el-timeline-item>
                    <el-timeline-item placement="top" color="#146BA1" hide-timestamp v-if="data.gridReportStatusEntity&&data.gridReportStatusEntity.gridReplay">
                        <div class="timeline">
                            <div class="timeline-left">
                                <div>网格员处理</div>
                                <div>{{data.gridReportStatusEntity.gridReplayTime ||''}}</div>
                            </div>
                            <div class="timeline-right">
                                <div>网格员：{{data.gridReportStatusEntity&&data.gridReportStatusEntity.gridReplay?data.gridReportStatusEntity.gridReplay:''}}</div>
                                <span class="status">{{data.gridReportStatusEntity&&data.gridReportStatusEntity.userEvaluation?'已评价':'待评价'}}</span>
                                 <el-image
                                    class="img"
                                    fit="cover" 
                                    v-for="(item,index) in imgArr2" :key="index"
                                    :src="item"
                                    :preview-src-list="imgArr2"
                                >
                                </el-image>
                                <video 
                                    :src="data.gridReportStatusEntity.gridReplayVideo" 
                                    class="video2"
                                    v-if="data.gridReportStatusEntity&&data.gridReportStatusEntity.gridReplayVideo" 
                                    controls
                                ></video>
                            </div>
                        </div>
                    </el-timeline-item>
                    <el-timeline-item placement="top" color="#146BA1" hide-timestamp v-if="data.gridReportStatusEntity&&data.gridReportStatusEntity.userEvaluation">
                        <div class="timeline">
                            <div class="timeline-left">
                                <div>上报人评价</div>
                                <div>{{data.gridReportStatusEntity.InhabitantReplayTime ||''}}</div>
                            </div>
                            <div class="timeline-right">
                                <div>评价：{{data.gridReportStatusEntity&&data.gridReportStatusEntity.userEvaluation?data.gridReportStatusEntity.userEvaluation:''}}</div>
                                <span class="status">已完成</span>
                            </div>
                        </div>
                    </el-timeline-item>
                </el-timeline>
                </div>
            </div>
       </div>
       <img  src="../../src/assets/img/close.png" class="close" @click="toClose"/>   
    </div>
</template>
<script>
import { number } from 'echarts'
import {secrecyMobile}from '../../src/utils/util'
import {getGridReply} from '../../src/api/api.js'
export default {
        name:"bulletBox1",
        props: {
			id:{
                type:Number,
                default:0
            }
		},
        data() {
            return {
                data:{},
                imgArr:[],
                imgArr2:[],
                loading:true
            }
        },
        created () {
         
        },
        mounted() {
            this.getData()
        },
        destroyed(){
 
        },
        methods: {
             mobile(phone){
                return secrecyMobile(phone)
            },
            getData(){
                getGridReply({
                    formkey:'DuPGENwi',
                    id:this.id,
                }).then(res=>{
                    // console.log(res)
                    // console.log(this.data.gridReportStatusEntity&&this.data.gridReportStatusEntity.gridReplayImage)
                    this.loading=false
                    this.data=res.data.data
                    this.imgArr=this.data.originalData&&this.data.originalData.image_upload1669626478545?this.data.originalData.image_upload1669626478545:[]
                    this.imgArr2=this.data.gridReportStatusEntity&&this.data.gridReportStatusEntity.gridReplayImage?this.data.gridReportStatusEntity.gridReplayImage.split(','):[]
                    
                })
            },
            toClose(){
                this.$emit('close')
            }
        }
}
</script>

<style scoped lang="less">

.video1{
    width:100%;
    max-height: 270px;
    margin-bottom: 30px;
}
.video2{
    width:100%;
    max-height: 200px;
}
::v-deep .el-timeline-item__tail {
  border-left:1px solid #1A4062;
}
// ::v-deep .el-timeline-item__node--normal{
//     left:0
// }
.timeline{
    display: flex;
}
.pl{
    padding-left:10px;
}
.evement-box{
    padding:10px 23px;
    border-radius: 2px;
    background-image: linear-gradient(to right,#012f5a,#031d3b);
}
.evement-title {
    font-size: 18px;
    // font-weight: bold;
    color: #ffffff;
    &::before{
        content:'';
        display: inline-block;
        width: 6.5px;
        height: 6.5px;
        background:#1378B6;
        border-radius: 50%;
        margin-right:6px;
    }
}
.describe{
    padding:20px;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    // font-weight: bold;
    color: #ffffff;
}
.timeline-left{
    // width:194.5px;
    width:35%;
    color:#fff;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    margin-right:5%;
    // font-weight: bold;
}
.timeline-right{
    // width: 333.5px;
    width: 60%;
    position: relative;
    color:#fff;
    padding:40px 15.5px 20.5px;
    background: #012f5a;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    // font-weight: bold;
    color: #ffffff;
}
.timeline-right .img{
    width:83px;
    height:83px;
    margin-right: 8px;
    margin-top: 8px;
}
.timeline-right .status{
    position: absolute;
    top:9.19px;
    right:8.15px;
    padding:0 12.25px;
    font-size: 15px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    // font-weight: bold;
    color: #ffffff;
    background: #146ba1;
    border-radius: 3px;
}
.box{
     width: 610px;
    height: 800px;
    background-image: url('../../src/assets/img/box/box-bg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    display: flex;
    flex-direction: column;
    position: relative;
}
.topic{
    width:100%;
    padding-top: 5px;
    height: 71px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height:51px;
    text-align: center;
    font-size: 23px;
    // font-weight: bold;
    color: #ffffff;
    margin-top: 31px;
}
.title{
    // width: 365.5px;
    font-weight: bold;
}
.border{
    width: 365.5px;
    height: 1px;
}
.box-content{
    padding:30px 66px;
    flex:1;
    overflow: scroll;
    color:#fff;
}
.box-content .box-text{
    width:100%;
    /* height: 300px; */
    font-size: 18px;
    color: #ffffff;
    line-height: 36px;
    text-indent: 2em;
}
.box-imgs{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.box-img{
    margin-right: 10px;
    margin-bottom: 30px;
}
.box-img:nth-of-type(2n){
    margin-right:0;
}
.box-imgs img{
    width: 380px;
    height: 206px;
}
.close{
    width: 87px;
    height: 87px;
    position: absolute;
    top:-25px;
    right:-73px;
}
.close:hover{
    cursor:pointer;
}
</style>