<template>
    <el-table
        :data="tableData"
        height="100%"
        style="width:100%;font-size:0.875em"
        :row-style="{height:'1.875em',background: '#02082a',color:'#fff'}"   
        :header-cell-style="{background:'#102e6d',color:'#fff',width:'100%',height:'2.375em',}"
        ref="rolltable"
        @row-click="handleTableRow"
        @mouseenter.native="stopScroll"
        @mouseleave.native="startScroll"
    >
    <!-- :row-class-name="tableRowClassName" -->
        <el-table-column
            prop="title"
            label="网格"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.input1686813778257||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="社区网格长"
        >
            <template slot-scope="scope">
                <div>
                    {{(scope.row.input1686298318392||'')+'\n'+(mobile(scope.row.input1686298704271)||'')}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="全科网格员"
        >
            <template slot-scope="scope">
                <div>
                    {{(scope.row.input1686298328216||'')+'\n'+(mobile(scope.row.input1686298720684)||'')}}
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import {getVillage} from '../../src/api/api'
import {secrecyMobile}from '../../src/utils/util'
let rolltimer
export default {
        name:"left1_3",
        data() {
            return {
                tableData: [],
            }
        },
        created(){
            this.getData()
        },
        mounted(){
          this.tableScroll(false)
        },
        destroyed(){
 
        },
        methods: {
            handleTableRow(row, event, column) {
                this.$emit('clickTableRow',row)
                // console.log(row, event, column)
            },
            mobile(phone){
                return secrecyMobile(phone)
            },
            getData(){
                getVillage({
                    current: 0,
                    formKey:"6YHVgcUT",
                    queryId:"82",
                    queryParams: {
                        select1686298457228label:"蟠龙社区",
                    },
                    size: 100
                }).then(res=>{
                    // console.log(res,'知网格')
                    this.tableData=res.data.data.rows
                })
            },
             startScroll() {
                this.tableScroll(false);
            },

            stopScroll() {
                this.tableScroll(true);
            },

            //滚动方法
            tableScroll(stop) {
                if (stop) {
                    clearInterval(rolltimer)
                    return
                }
                const table = this.$refs.rolltable
                const divData = table.bodyWrapper
                rolltimer = setInterval(() => {
                    divData.scrollTop += 1
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        if (table.tableData.length >=3) {
                            divData.scrollTop = 0
                        }
                    }
                }, 35)
            },
            tableRowClassName({row, rowIndex}) {
                if (rowIndex) {
                return 'success-row';
                }
            }
        }
}
</script>
<style>
.el-table__header-wrapper{
    background-color: #102e6d;
}
.el-table .cell{
  white-space: pre-wrap !important;
}
/* .el-table .warning-row {
background: #102e6d;
color: #ffffff;
}

.el-table .success-row {
background: #02082a;
color: #ffffff;
} */
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:0;
}
 /* 去掉表格单元格边框 */
.el-table th{
    border:none;
  }
.el-table td,.el-table th.is-leaf {
  border:none;
}
 /* 表格最外边框 */
.el-table--border, .el-table--group{
  border: none;
}
 /* 头部边框 */
.el-table thead tr th.is-leaf{
  border-bottom: none;
  border-right: none;
}
/* 表格最外层边框-底部边框 */
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.el-table::before{
  width: 0;
}
.el-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
/* 表格有滚动时表格头边框 */
/* .el-table--border th.gutter:last-of-type {
    border: 1px solid #EBEEF5;  
    border-left: none;
} */
.el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #02082A !important;
}
.el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #6495ED !important;
}
.current-row > td {
   background: #6495ED !important;
}
::v-deep .is-scrolling-none {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
}
::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}
.el-table--scrollable-x .el-table__body-wrapper {
overflow-x: hidden;
}
</style>