<template>
    <div  
        v-loading="loading"  
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)">
        <!-- <div style="overflow-x:scroll;width:100%;">
            <div style="display:flex;width: fit-content">
                <div style="display:flex;flex-wrap:wrap;width:770px;">
                    <div style="display:flex;margin-right:8.5px;align-items: center;margin-bottom:30px;" v-for="i in 4" :key="i">
                        <el-image
                            style="width: 92.5px; height: 104px;margin-right:8.5px;"
                            :src="require('../../src/assets/img/tou.png')"
                            fit="contain">
                        </el-image>
                        <div class="bg info">
                            <div>网格管理员：张海英</div>
                            <div>网格格言：您的满意就是我最大的动力</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- 网格风采 -->
        <el-carousel :interval="5000" indicator-position="outside" height="1.35rem" v-if="id==1">
            <el-carousel-item v-for="(item,index) in gridArr" :key="index">
                 <div class="big-box">
                    <div class="info-box" v-for="data in item" :key="data.id">
                        <el-image
                            class="img"
                            :src="data.image_upload1685346318991&&data.image_upload1685346318991[0]&&data.image_upload1685346318991[0].url?data.image_upload1685346318991[0].url:require('../../src/assets/img/tou.png')"
                            fit="contain"
                            :preview-src-list="data.image_upload1685346318991.map(res=>res.url)"
                        >
                        </el-image>
                        <div class="bg info">
                            <div>{{data.input1685432547307||'网格员'}}：{{data.input1685346293559||'未知'}}</div>
                            <div class="geyan">网格格言：{{data.textarea1688461150594||'暂无格言'}}</div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <el-carousel :interval="3000" arrow="always" indicator-position="none" height="1.45rem" v-if="id==1">
            <el-carousel-item v-for="item in activityArr" :key="item.id">
               <div class="img-box">
                    <el-image
                        style="width: 100%;height:100%;"
                        :src="item.image_upload1688520777543[0].url"
                        fit="cover"
                        :preview-src-list="item.image_upload1688520777543.map(res=>res.url)"
                    >
                    </el-image>
                    <div class="title"></div>
                    <div class="font">{{item.input1688520762998}}</div>
               </div>
            </el-carousel-item>
        </el-carousel>
        <!-- 党群活动中心 -->
        <div style="height:1.85rem;margin-bottom:0.1rem" v-if="id==2">
            <el-image
              style="width:100%; height:100%;"
              :src="require('../../src/assets/img/life1.jpg')"
              fit="cover"
              :preview-src-list="[require('../../src/assets/img/life1.jpg')]"
            >
            </el-image>
        </div>
         <el-carousel :interval="3000" arrow="always" indicator-position="none" height="1.85rem" v-if="id==2">
            <el-carousel-item v-for="item in partArr" :key="item.id">
               <div class="img-box">
                    <el-image
                        style="width: 100%;height:100%;"
                        :src="item.image_upload1688607378859[0].url"
                        fit="cover"
                        :preview-src-list="item.image_upload1688607378859.map(res=>res.url)"
                    >
                    </el-image>
                    <div class="title"></div>
                    <div class="font">{{item.input1688607366231||''}}</div>
               </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import {getVillage} from '../../src/api/api'
export default {
        name:"activity",
        props: {
            id:{
                type:String,
                default:'0'
            }
		},
        data() {
            return {
                activityArr:[],
                partArr:[],
                gridArr:[],
                loading:true,
            }
        },
        created () {
            if(this.id==1){
                this.getData()
                this.getActivity()
            }else if(this.id==2){
                this.getParty()
            } 
        },
        mounted() {
        },
        destroyed(){
 
        },
        methods: {
            // getSrcList(){
            //     return ['https://smartserver-file.oss-cn-chengdu.aliyuncs.com/shehong/image31.jpg?random=' + Math.random()]
            // },
             getData(){
                getVillage({
                    formKey: "832QNF0v",
                    queryId: "100",
                    queryParams: {
                        select1685346366383label: "蟠龙社区"
                    },
                    size: 100,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    let array=res.data.data.rows
                    let list=[]
                    let arr=[]
                    for (let index = 0; index < array.length; index++) {
                        if(array.length<4){
                            arr.push(array[index])
                            list.push(arr)
                        }else{
                            if((index+1)%4==0){
                                arr.push(array[index])
                                list.push(arr)
                                arr=[]
                            }else{
                                arr.push(array[index])
                            } 
                        }
                    }
                    if( arr.length>0){
                        list.push(arr)
                    }
                    this.gridArr=list
                    // console.log(this.gridArr,'风采人')
                    // console.log(res,'风采')
                })
            },
            getActivity(){
                getVillage({
                    formKey: "AsFC91Q6",
                    queryId: "101",
                    queryParams: {
                        select1688520733303label: "蟠龙社区"
                    },
                    size: 100,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    this.activityArr=res.data.data.rows||[]
                    // console.log(res,'活动')
                })
            },
            getParty(){
                getVillage({
                    formKey: "eHzcIZlE",
                    queryId: "105",
                    queryParams: {
                        select1688607344117label: "蟠龙社区"
                    },
                    size: 100,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    this.partArr=res.data.data.rows||[]
                    // console.log(res,'党群活动')
                })
                
            },
            toClose(){
                this.$emit('close')
            }
        }
}
</script>

<style scoped>
.img{
    width: 92.5px; 
    height: 104px;
    margin-right:8.5px;
}
.info-box{
    display:flex;
    margin-right:8.5px;
    align-items: center;
    margin-bottom:30px;
}
.big-box{
    display:flex;
    flex-wrap:wrap;
    width:770px;
}
.bg{
    width:275px;
    min-height:104px;
    background-image:linear-gradient(to right, #1A4062 , #041e3c);
    display: flex;
    padding:10px 0 10px 17px;
    flex-direction: column;
    justify-content: center;
}
.info{
    font-size: 18px;
    color: #ffffff;
}
.title{
    width: 100%;
    height: 57px;
    line-height:57px;
    opacity: 0.61;
    background: #1a4062;
    position: absolute;
    bottom: 0;
    left:0;
    color:#fff
}
.font{
    width: 100%;
    height: 57px;
    line-height:57px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left:0;
}
.img-box{
    width: 100%;
    height:100%;
    position: relative;
}
.geyan{
    width:100%;
    /* 超出文本隐藏 */
    overflow: hidden;
    /* 超出部分省略号显示 */
    text-overflow: ellipsis; 
    word-break: break-all;
    /* 弹性盒模型 */
    display: -webkit-box; 
    /* 上下垂直 */
    -webkit-box-orient: vertical; 
    /* 自定义行数 */
    -webkit-line-clamp: 2; 
}
</style>