<template>
    <div class="com-page" style="display: block;white-space: nowrap;">
        <!-- <div style="width:60%;height:100%;display: inline-block;"> -->
            <right1leftb ></right1leftb>
        <!-- </div> -->
        <!-- <div  style="width:40%;height:100%;display: inline-block;">
            <right1></right1>    
        </div> -->
        

        

    </div>
</template>

<script>
    import right1 from '../src/components/right1';
    import right1leftb from '../src/components/right1leftb';

    export default{
        name:"right1Page",
        components: { 
            // right1,
            right1leftb,

        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>