<template>
    <div class="com-container" >
        <div class="com-chart" ref="right4_ref"></div>
    </div>
</template>

<script>
    import {getReportTrend} from '../api/api'
    export default{
        name:"right4",
        data() {
            return {
                chartInstance:null,
                // allData:null,    //服务器返回的数据
            };
        },
        mounted() {
            this.initChart()
            this.getData()
            window.addEventListener('resize',this.screenAdapter)
            //页面加载完成后主动进行屏幕的适配
            // this.screenAdapter()
        },
        destroyed(){
            //在组件销毁的时候需要将监听器取消
            window.removeEventListener('resize',this.screenAdapter)
        },
        methods: {
            //初始化echartInstance对象
            initChart(){
                this.chartInstance = this.$echarts.init(this.$refs.right4_ref)
                //对图表初始化配置的控制
                const initOption = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        with:'auto',
                        left: '0',
                        right: '1%',
                        bottom: '3%',
                        top: '10%',
                        containLabel: true,
                        show:true,
                        backgroundColor: "#031857",
                        borderColor: "#123f7a"

                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        // data: ['2/07', '2/08', '2/09', '2/10', '2/11', '2/12', '2/13'],
                        splitLine: { 
                            show: true,
                            lineStyle: {
                                color: ['#003a87'],
                                width: 1,
                                type: 'solid'
                            }
                        },
                        axisLine:{
                            show:true,
                            lineStyle:{
                                color:'#003a87'
                            }
                        },
                        axisTick:{
                            show:false
                        },
                        // splitArea: {
                        //     show: true,
                        //     areaStyle: {
                        //         color: ['rgba(7, 23, 56,0.5)']
                        //     }
                        // }

                    },
                    yAxis: {
                        type: 'value',
                        // data: [200,400,600,800,1000,1200,1400],
                        splitNumber: 4,
                        splitLine: { 
                            show: true,
                            lineStyle: {
                                color: ['#003a87'],
                                width: 1,
                                type: 'solid'
                            } 
                        },
                        axisLine:{
                            show:true,
                            lineStyle:{
                                color:'#003a87'
                            }
                        },
                    },
                    series: [
                        {
                            name: '上报事件',
                            type: 'line',
                            smooth: true,
                            stack: 'Total',
                            // data: [120, 132, 101, 134, 90, 230, 1010],
                            symbol:'none',
                            itemStyle: {
                            normal: {
                                color: '#75d333',
                                lineStyle:{
                                    width:1.5,//设置线条粗细
                                      color: '#ff784e'

                                }
                            },

                        }
                            
                        },
                    ]
                }
                this.chartInstance.setOption(initOption)
            },
            //获取服务器数据
            async getData(){
                // const {data:ret} = await this.$http.get('yourUrl')
                // console.log(ret)
                // this.allData = ret
                
                let params = {
                    day:7,
                    customerId: 5,
                };
                getReportTrend(params).then(res => {
                    // console.log(res)
                    if(res.data.code===201){
                        var data = res.data.data;
                        // data: ['2/07', '2/08', '2/09', '2/10', '2/11', '2/12', '2/13'],
                        // var data = [['2/07',200],['2/08',400],['2/09',600],['2/10', 800],['2/11',1000],['2/12', 1200],['2/13',1400]]

                        this.updateChart(data)

                    }
                }).catch(err => {
                    // console.log(err)
                })
            },
            //更新图表
            updateChart(data){
                // const left1Names = this.allData.map((item) => {
                //     return item.name
                // })
                // const left1Values = this.allData.map((item) => {
                //     return item.value
                // })
                const dataOption = {
                    series: [
                        {
                            data: data,
                            // type: 'line',
                        }
                    ]
                }
                this.chartInstance.setOption(dataOption)
            },
            //当浏览器的大小发生变化的时候会调用的方法，来完成屏幕的适配
            screenAdapter(){
                // console.log(this.$refs.left1_ref.offsetWidth)
                // const titleFontSize = this.$refs.left1_ref.offsetWidth / 100 *3.6
                const titleFontSize = this.$refs.right4_ref.offsetWidth / 100 *3.6
                // console.log(titleFontSize)
                //和分辨率相关的配置项
                const adapterOption = {
                    title:{
                        textStyle:{
                            fontSize:titleFontSize,
                        }
                    }
                }
                this.chartInstance.setOption(adapterOption)
                //手动调用图表对象的resize才能产生效果
                this.chartInstance.resize()
            }
        },

    }
</script>