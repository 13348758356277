<template>
    <div class="box">
       <div class="topic">
            <!-- {{data.input1666833756376?data.input1666833756376+'架构图':'架构图'}} -->
            {{data.label?data.label+'架构图':'架构图'}}
        </div> 
       <div class="box-content">
           <div style="height:100%;overflow:scroll">
                <vue2-org-tree
                    :data="data"
                    :horizontal="true"
                    :renderContent="renderContent"
                    />
                <!-- image_upload1669874858627 -->
                    <!-- <div class="box-imgs">
                        <img :src="item.url" v-for="(item,index) in data.image_upload1669874858627" :key="index" class="box-img"/>
                    </div>
                    <div class="box-text">
                    {{data.textarea1686896544482||'暂无介绍'}}
                    </div> -->
                </div> 
           </div>
        <img  src="../../src/assets/img/close.png" class="close" @click="toClose"/>
    </div>
</template>
<script>
export default {
        name:"bulletBox",
        props: {
			data:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
		},
        data() {
            return {
            }
        },
        mounted() {
        },
        destroyed(){
 
        },
        methods: {
             //渲染节点
            renderContent(h, data) {
                let fontSize=`${'font-size:'+data.style+'em'+';'+'white-space:pre-wrap'}`
                let img=require('../../src/assets/img/party.png')
                    return data.isPartyMember?(
                        <div style="position:relative">
                            <span style={fontSize}>{data.label}</span>
                            <img src={img} class="info-img"/>
                        </div>
                    
                ):(<span style={fontSize}>{data.label}</span>);
            },
            toClose(){
                this.$emit('close')
            }
        }
}
</script>

<style scoped>
.org-tree-container{
  background: transparent;
  padding:0 15px;
}
::v-deep .org-tree-node-label-inner{
  background: #102E6D;
  color:#fff;
  font-size: 14px;
}
.box{
    width: 904px;
    height: 726px;
    background-image: url('../../src/assets/img/box/box-bg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    display: flex;
    flex-direction: column;
    position: relative;
}
.topic{
    width:100%;
    padding-top: 5px;
    height:94px;
    line-height:94px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 31px;
}
.box-content{
    padding:0 30px 66px;
    flex:1;
    overflow: hidden;
}
.box-content .box-text{
    width:100%;
    /* height: 300px; */
    font-size: 18px;
    color: #ffffff;
    line-height: 36px;
    text-indent: 2em;
}
.box-imgs{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.box-img{
    margin-right: 10px;
    margin-bottom: 30px;
}
.box-img:nth-of-type(2n){
    margin-right:0;
}
.box-imgs img{
    width: 380px;
    height: 206px;
}
.close{
    width: 87px;
    height: 87px;
    position: absolute;
    top:-25px;
    right:-73px;
}
.close:hover{
    cursor:pointer;
}
::v-deep .info-img{
    width:13.5px;
    height:13.5px;
    /* margin-left:6.5px; */
    position:relative;
    top:-6.5px;
    right:-3.5px;
}
</style>