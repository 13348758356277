<template>
    <div class="com-page">
        <right3a></right3a>
    </div>
</template>

<script>
    import right3a from '../src/components/right3a'
    export default{
        name:"right3Page1",
        components: { 
            right3a
        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>