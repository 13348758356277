<template>
    <div class="box" :class="[short?'short':'long']">
       <div class="topic" v-if="showTitle">
            {{name}}
        </div> 
       <div class="box-content">
            <slot></slot>
        </div> 
        <img  src="../../src/assets/img/close.png" class="close" @click="toClose"/>
    </div>
</template>
<script>
export default {
        name:"bulletBox2",
        props: {
			name:{
                type:String,
                default:''
            },
            showTitle:{
                type:Boolean,
                default:true
            },
            short:{
                type:Boolean,
                default:false
            },
		},
        data() {
            return {
                
            }
        },
        mounted() {
            
        },
        destroyed(){
 
        },
        methods: {
            toClose(){
                this.$emit('close')
            }
        }
}
</script>

<style scoped>
.short{
    width:726px ;
    height: 904px;
}
.long{
    width: 904px;
    height: 726px;
}
.box{
    background-image: url('../../src/assets/img/box/box-bg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    display: flex;
    flex-direction: column;
    position: relative;
}
.topic{
    width:100%;
    padding-top: 5px;
    height:94px;
    line-height:94px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 31px;
}
.box-content{
    padding:0 66px 30px;
    flex:1;
    overflow: scroll;
}
.box-content .box-text{
    width:100%;
    /* height: 300px; */
    font-size: 18px;
    color: #ffffff;
    line-height: 36px;
    text-indent: 2em;
}
.box-imgs{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.box-img{
    margin-right: 10px;
    margin-bottom: 30px;
}
.box-img:nth-of-type(2n){
    margin-right:0;
}
.box-imgs img{
    width: 380px;
    height: 206px;
}
.close{
    width: 87px;
    height: 87px;
    position: absolute;
    top:-25px;
    right:-73px;
}
.close:hover{
    cursor:pointer;
}
</style>