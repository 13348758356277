<template>
  <div class="com-container" >
      <div class="com-chart" ref="right1_ref"></div>
  </div>
</template>

<script>
  import { getPersonCompared2,getType } from '../../src/api/api'
  export default{
      name:"left1",
      data() {
          return {
              chartInstance:null,
              num:0
          };
      },
      mounted() {
        this.getData()
        const timer = setInterval(() => {
          // console.log("每10min刷新一次")
          this.getData()
        }, 600 * 1000);
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once('hook:beforeDestroy', () => {
          clearInterval(timer);
        })
      },
      destroyed(){
 
      },
      methods: {
          //初始化echartInstance对象
          initChart(data){
              this.chartInstance = this.$echarts.init(this.$refs.right1_ref)
              //对图表初始化配置的控制
              const initOption = {
                tooltip: {
                  // trigger: 'item'
                },
                legend: {
                  orient: "vertical",
                  y: "center",
                  // right: 20,
                  left:'60%',
                  textStyle: {
                    color: '#FFFFFF'
                  }
                },
                // graphic: [{ //环形图中间添加文字
                //   type: 'text', //通过不同top值可以设置上下显示
                //   left: '22',
                //   top: '40%',
                //   style: {
                //       text: '上报共计'+'\n'+ `${this.num}件`,
                //       textAlign: 'center',
                //       fill: '#FFFFFF', //文字的颜色
                //       fontSize: 16,
                //       lineHeight: 24,
                //   }
                // }],
                grid: {
                  left: 0,
                  right: '0%',
                  bottom: '0',
                  // containLabel: true,
                  width: '60%',
                  height: "90%",
                },
                series: [{
                    type: 'pie',
                    radius: ['40%', '70%'],
                    center:['30%',"50%"],
                    label:{
                      show:true,
                      position: 'center',
                      formatter:() => {
                          let str = '上报共计'+'\n'+ `${this.num}件`
                          return str
                      },
                      fontSize: 16,
                      lineHeight: 24,
                      color: '#FFFFFF',
                    },
                    labelLine: {
                      show: false
                    },
                    data: [
                      {name:`民⽣服务类${data.minSheng||0}件`,value:data.minSheng||0},
                      {name:`⽭盾劝解类${data.maoDun||0}件`,value:data.maoDun||0},
                      {name:`政策法规类${data.zhengCe||0}件`,value:data.zhengCe||0},
                      {name:`社情民意类${data.sheQing||0}件`,value:data.sheQing||0},
                      {name:`特殊⼈群服务管理${data.specialPopulation||0}件`,value:data.specialPopulation||0},
                      {name:`治安防控${data.zhiAn||0}件`,value:data.zhiAn||0},
                      {name:`突发事件报告${data.emergencies||0}件`,value:data.emergencies||0},
                      {name:`其他类${data.other||0}件`,value:data.other||0},
                    ]
                  }]
              }
              this.chartInstance.setOption(initOption)
          },
          getData() {
            // let params = {
            //   customerId: 5,
            //   ageDivide: "18,40,60"
            // };
            // getPersonCompared2(params).then(res => {
            //   console.log("人口信息对比")
            //   console.log(res)
            //   var data = res.data.data
            //   console.log(data.ageData)

            //   this.initChart(data.ageData)
            // }).catch(err => {
            //   console.log(err)
            // })
            getType({
                formKey:"DuPGENwi",
            }).then(res=>{
                // console.log(res,'事件')
                this.num=res.data.data.total
                this.initChart(res.data.data)
            })
          }
      },

  }
</script>