<template>
    <div id="menu">
        <el-container  style="height: 100%;">
            <el-main class="subject" >
                <el-row :gutter="13" class="row">
                    <el-col :span="8" @click.native="seeDrop('总面积')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/mj.png" class="img1">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">总面积</div>
                            <div>
                                <span class="num">2.5</span>
                                <span class="num unit">平方公里</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('总人口')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/rk.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">总人口</div>
                            <div>
                                <span class="num">{{peopleNum}}</span>
                                <span class="num unit">人</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('驻辖区单位')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/zxq.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">驻辖区单位</div>
                            <div>
                                <span class="num">{{data.unit}}</span>
                                <span class="num unit">个</span>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row :gutter="13" class="row">
                    <el-col :span="8" @click.native="seeDrop('小区')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/sylp.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">小区</div>
                            <div>
                                <span class="num">{{data.village}}</span>
                                <span class="num unit">个</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('学校')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/xx.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">学校</div>
                            <div>
                                <span class="num">{{data.school}}</span>
                                <span class="num unit">所</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('餐饮、小吃')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/cy.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">餐饮、小吃</div>
                            <div>
                                <span class="num">{{data.snack}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
                 <el-row :gutter="13" class="row">
                    <el-col :span="8" @click.native="seeDrop('酒店')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/jiu.png" class="img1">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">酒店</div>
                            <div>
                                <span class="num">{{data.hotel}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('景点')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/jd.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">景点</div>
                            <div>
                                <span class="num">{{data.sight}}</span>
                                <span class="num unit">处</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('健身活动广场')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/gc.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">健身活动广场</div>
                            <div>
                                <span class="num">{{data.fitness}}</span>
                                <span class="num unit">个</span>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row :gutter="13" class="row">
                    <el-col :span="8" @click.native="seeDrop('医院')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/yy.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">医院 </div>
                            <div>
                                <span class="num">{{data.hospital}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('药店')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/yd.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">药店</div>
                            <div>
                                <span class="num">{{data.pharmacy}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('银行')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/yh.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">银行</div>
                            <div>
                                <span class="num">{{data.bank}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
                 <el-row :gutter="13" class="row row1">
                    <el-col :span="8" @click.native="seeDrop('快递网点')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/kd.png" class="img1">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">快递网点</div>
                            <div>
                                <span class="num">{{data.express}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('中型超市')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/cs.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">中型超市</div>
                            <div>
                                <span class="num">{{data.supermarket}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('便民管家')" class="click">
                        <el-col :span="8">
                             <img src="../../src/assets/img/community/bm.png" class="img2">
                        </el-col>
                        <el-col :span="16">
                            <div class="name">便民管家</div>
                            <div>
                                <span class="num">{{list.length||0}}</span>
                                <span class="num unit">家</span>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>
<script>
// import { getStatistics } from '../../api/data.js'
import {getPartyBuilding,getVillage,postFormAPI, putSomeAPI, deleteListAPI} from '../../src/api/api'
import axios from 'axios'
import ArrayPower from '../../src/utils/arry.js'
import { number } from 'echarts'
export default {
        name:"frame",
        data() {
            return {
                loginTotal:0,
                partyTotal:0,
                partyTotalSex0:0,
                partyTotalSex1:0,
                size1:0,
                size2:0,
                manPer:0,
                womanPer:0,
                intervalId:null,
                list:[],//便民管家
                mapList:[],
                data:{
                    unit:0,
                    village:0,
                    school:0,
                    snack:0,
                    hotel:0,
                    sight:0,
                    fitness:0,
                    hospital:0,
                    pharmacy:0,
                    bank:0,
                    express:0,
                    supermarket:0,
                },
              peopleList:[],
              peopleNum:0,

            }
        },
        props:{
            people:{
                type:Number||String,
                default:0
            }
        },
        created(){
            this.peopleNum=this.people
            // this.dataRefreh();
            this.getPhone()
            this.getMapData()
            this.getVillageData()
        },
        mounted() {
            // let params = {
            //     customerId: 5,
            // };
            // getStatistics(params).then(res => {
            //     // console.log(res)
            //     if(res.data.code===201){
            //         var data = res.data.data;
            //         this.personTotal=data.personTotal
            //         this.partyMember=data.partyMember
            //         this.permanentTotal=data.permanentTotal
            //         this.flowTotal=data.flowTotal
            //         this.selfEmployedTotal=data.selfEmployedTotal
            //         this.company=data.company
            //         this.educationalInstitution=data.educationalInstitution
            //         this.hotel=data.hotel
            //     }
            // }).catch(err => {
            //     console.log(err)
            // })
            // this.getData()

        },
        destroyed(){

        },
        methods: {
            // 获取便民管家列表
            getPhone(){
                getVillage({
                    formKey: "ucN3ahF6",
                    queryId: "108",
                    queryParams: {
                    select1689140601082label: "蟠龙社区"
                    },
                    size: 500,
                    current: 0
                }).then(res=>{
                    this.list=res.data.data.rows
                    // console.log(res,'res')
                })
            },
            seeDrop(name){
                this.$emit('drop',name)
            },
            getData(){
                let params = {
                    customerId: 5,
                };
                getPartyBuilding(params).then(res => {
                    // console.log("左1")
                    // console.log(res)
                    if(res.data.code == 200){
                        var data = res.data.data
                        this.loginTotal = data.loginTotal
                        this.partyTotal = data.partyTotal
                        this.partyTotalSex0 = data.partyTotalSex0
                        this.partyTotalSex1 = data.partyTotalSex1
                        this.size1 = data.size1
                        this.size2 = data.size2
                        var total = data.partyTotalSex0 + data.partyTotalSex1
                        this.manPer = parseFloat(Number(data.partyTotalSex1/total).toFixed(2))*100
                        this.womanPer = parseFloat(Number(data.partyTotalSex0/total).toFixed(2))*100
                    }
                }).catch(err => {
                    // console.log(err)
                })
            },
            //获取地图小区数据
            getMapData(){
                getVillage( {
                    formKey: "ymygnAao",
                    queryId: "96",
                    queryParams: {
                        select1687931663500label: "蟠龙社区",
                    },
                    size: 100,
                    current: 0
                }).then(res=>{
                    // console.log(res,'等等')
                    this.mapList=res.data.data.rows
                    this.getNum()
                })
            },
            getNum(){
                const newArr = ArrayPower.groupBy(this.mapList, 'input1687931710516');
                for (const key in newArr) {
                    switch (key) {
                        case '驻辖区单位':
                           this.data.unit=newArr[key].length
                            break;
                        case '小区':
                            this.data.village=newArr[key].length
                            break;
                        case '学校':
                            this.data.school=newArr[key].length
                            break;
                        case '餐饮、小吃':
                            this.data.snack=newArr[key].length
                            break;
                        case '酒店':
                            this.data.hotel=newArr[key].length
                            break;
                        case '景点':
                            this.data.sight=newArr[key].length
                            break;
                        case '健身活动广场':
                            this.data.fitness=newArr[key].length
                            break;
                        case '医院':
                            this.data.hospital=newArr[key].length
                            break;
                        case '药店':
                            this.data.pharmacy=newArr[key].length
                            break;
                        case '银行':
                            this.data.bank=newArr[key].length
                            break;
                        case '快递网点':
                            this.data.express=newArr[key].length
                            break;
                        case '中型超市':
                            this.data.supermarket=newArr[key].length
                            break;
                        default:
                            ''
                        }
                }
            },
          getVillageData(){
            getVillage({
              formKey: "RW5txIHC",
              queryId: "92",
              queryParams: {
                select1688450430067label: "蟠龙社区"
              },
              size: 100,
              current: 0
            }).then(res=>{
              this.peopleList=res.data.data.rows
              this.peopleNum=this.peopleList.reduce((sum, obj) => sum + Number(obj.input1688435073349), 0)
            //   console.log(this.peopleNum)
            })
          },
            // 定时刷新数据函数
            dataRefreh() {
                // 计时器正在进行中，退出函数
                if (this.intervalId != null) {
                    return;
                }
                // 计时器为空，操作
                this.intervalId = setInterval(() => {
                    // console.log("刷新" + new Date());
                    this.getData(); //加载数据函数
                }, 600 * 1000);
            },
            // 停止定时器
            clear() {
                clearInterval(this.intervalId); //清除计时器
                this.intervalId = null; //设置为null
            },
        }
}
</script>
<style scoped>

    #menu {
        width:90%;
        /* height: 90%; */
        /* background-color: aquamarine; */
        /* float: right; */
        margin-top: 2%;
        margin-left: 5%;
    }
    .row{
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        border-bottom: 1px solid #0d2e3a;
    }


    clearfix
    .head{
        color: #d3e5f4;
        font-size: 16px;
        font-weight: 500;
        /* background-color:#0c3374; */
        height: 20%;
        width: 100%;
        text-align: center;
        display: inline-block;
        margin-bottom: 0;
        line-height: 40px;
    }
    .subject{
        padding:5px;
        /* background-color:rgb(0, 169, 211); */
        /* height: 78.5%; */
        width: 100%;
        text-align: center;
        margin-bottom: 0;

    }

  .el-row {
    /* height: 50%; */
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }
  .el-col {
    border-radius: 4px;
  }

  span{
      /* color: #8ab3d1;
      font-size: 16px; */
      color: #fff;
      font-size: 14px;
      font-weight: 500;
  }
  .perNum{
      position: absolute;
      top:18px;
      left:26px;
      font-size: 6px;
  }
  .perNum1{
    position: absolute;top: 20px;right:118px;font-size: 6px;
  }
  .per{
    position: absolute;
      top:30px;
      left:28px;
      font-size: 6px;
  }
  .per1{
    position: absolute;top: 32px;right:119px;font-size: 6px;
  }
  .tag{
    text-align: left;position: relative;left: 6px;

  }
  .row1{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    border-bottom: 0;
  }
  .col{
    margin-bottom: 4px;
  }
  .tag img{
    margin-right: 9px;
    vertical-align: middle;
  }
  .row .num{
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .row .unit{
    color: #00DDD3;
  }
  .row .img1{
    width: 40px;
    height: 54px;
  }
  .row .img2{
    width: 38px;
    height: 55px;
  }
  .tag .img3{
    width:20px;
    height:18px;
  }
  .tag .img4{
    width:19px;
    height:19px;
  }
  .tag .img5{
    width:17px;
    height:17px;
  }
  .bg-tag{
    width: 140px;
    height: 38px;
    background-image: url('../../src/assets/img/bg-tag.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #0a99f3;
  }
  .row .name{
    opacity: 0.62;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
  .click{
    cursor: pointer;
  }
  .row .pingfang{
    font-size: 14px;
    font-weight: bold;
    color: #00DDD3;
  }
</style>