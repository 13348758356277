<template>
    <div id="menu">
        <el-container  style="height: 100%;">
            <el-main class="subject" >
                <el-row :gutter="13" class="row row1">
                    <el-col :span="8" @click.native="seeDrop('网格分布图')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/fb.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格分布</div>
                            </div>
                        </el-col>
                    </el-col>
                     <el-col :span="8" @click.native="seeDrop('网格管理')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/zd.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格管理</div>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('网格风采')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/fc.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格风采</div>
                            </div>
                        </el-col>
                    </el-col>
                    <!-- <el-col :span="8" @click.native="seeDrop('网格员管理制度')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/zd.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格员管理制度</div>
                            </div>
                        </el-col>
                    </el-col> -->
                    <!-- <el-col :span="8" @click.native="seeDrop('全科网格员职能')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/zn.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格员职能</div>
                            </div>
                        </el-col>
                    </el-col> -->
                </el-row>
                <!-- <el-row :gutter="13" class="row row1">
                    <el-col :span="8" @click.native="seeDrop('网格员考核制度')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/kh.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格员考核制度</div>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('网格风采')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/fc.png">
                        </el-col>
                        <el-col :span="14">
                            <div class="name-box">
                                <div class="name">网格风采</div>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="8" @click.native="seeDrop('全科网格服务')" class="click">
                        <el-col :span="10">
                             <img src="../../src/assets/img/grid/qk.png">
                        </el-col>
                        <el-col :span="14">
                             <div class="name-box">
                                <div class="name">全科网格服务</div>
                            </div>
                        </el-col>
                    </el-col>
                </el-row> -->
            </el-main>
        </el-container>
    </div>
</template>
<script>
// import { getStatistics } from '../../api/data.js'
import {getPartyBuilding,postFormAPI, putSomeAPI, deleteListAPI} from '../../src/api/api'
import axios from 'axios'
export default {
        name:"frame",
        data() {
            return {
                loginTotal:0,
                partyTotal:0,
                partyTotalSex0:0,
                partyTotalSex1:0,
                size1:0,
                size2:0,
                manPer:0,
                womanPer:0,
                intervalId:null
            }
        },
        created(){
            // this.dataRefreh();
        },
        mounted() {
            // let params = {
            //     customerId: 5,
            // };
            // getStatistics(params).then(res => {
            //     // console.log(res)
            //     if(res.data.code===201){
            //         var data = res.data.data;
            //         this.personTotal=data.personTotal
            //         this.partyMember=data.partyMember
            //         this.permanentTotal=data.permanentTotal
            //         this.flowTotal=data.flowTotal
            //         this.selfEmployedTotal=data.selfEmployedTotal
            //         this.company=data.company
            //         this.educationalInstitution=data.educationalInstitution
            //         this.hotel=data.hotel
            //     }
            // }).catch(err => {
            //     console.log(err)
            // })
            // this.getData()

        },
        destroyed(){
 
        },
        methods: {
            seeDrop(name){
                this.$emit('drop',name)
            },
            getData(){
                let params = {
                    customerId: 5,
                };
                getPartyBuilding(params).then(res => {
                    // console.log("左1")
                    // console.log(res)
                    if(res.data.code == 200){
                        var data = res.data.data
                        this.loginTotal = data.loginTotal
                        this.partyTotal = data.partyTotal
                        this.partyTotalSex0 = data.partyTotalSex0
                        this.partyTotalSex1 = data.partyTotalSex1
                        this.size1 = data.size1
                        this.size2 = data.size2
                        var total = data.partyTotalSex0 + data.partyTotalSex1
                        this.manPer = parseFloat(Number(data.partyTotalSex1/total).toFixed(2))*100
                        this.womanPer = parseFloat(Number(data.partyTotalSex0/total).toFixed(2))*100
                    }
                }).catch(err => {
                    // console.log(err)
                })
            },
            // 定时刷新数据函数
            dataRefreh() {
                // 计时器正在进行中，退出函数
                if (this.intervalId != null) {
                    return;
                }
                // 计时器为空，操作
                this.intervalId = setInterval(() => {
                    // console.log("刷新" + new Date());
                    this.getData(); //加载数据函数
                }, 600 * 1000);
            },
            // 停止定时器
            clear() {
                clearInterval(this.intervalId); //清除计时器
                this.intervalId = null; //设置为null
            },
        }
}
</script>
<style scoped>

    #menu {
        width:90%;
        /* height: 90%; */
        /* background-color: aquamarine; */
        /* float: right; */
        margin-top: 2%;
        margin-left: 5%;
    }
    .row{
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        border-bottom: 1px solid #0d2e3a;
    }


    clearfix
    .head{
        color: #d3e5f4;
        font-size: 16px;
        font-weight: 500;
        /* background-color:#0c3374; */
        height: 20%;
        width: 100%;
        text-align: center;
        display: inline-block;
        margin-bottom: 0;
        line-height: 40px;
    }
    .subject{
        padding:5px;
        /* background-color:rgb(0, 169, 211); */
        /* height: 78.5%; */
        width: 100%;
        text-align: center;
        margin-bottom: 0;

    }

  .el-row {
    /* height: 50%; */
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }
  .el-col {
    border-radius: 4px;
  }

  span{
      /* color: #8ab3d1;
      font-size: 16px; */
      color: #fff;
      font-size: 14px; 
      font-weight: 500;
  }
  .perNum{
      position: absolute;
      top:18px;
      left:26px;
      font-size: 6px;
  }
  .perNum1{
    position: absolute;top: 20px;right:118px;font-size: 6px;
  }
  .per{
    position: absolute;
      top:30px;
      left:28px;
      font-size: 6px;
  }
  .per1{
    position: absolute;top: 32px;right:119px;font-size: 6px;
  }
  .tag{
    text-align: left;position: relative;left: 6px;
    
  }
  .row1{
    margin-left: 0;
    margin-right: 0;
    border-bottom: 0;
  }
  .col{
    margin-bottom: 4px;
  }
  .tag img{
    margin-right: 9px;
    vertical-align: middle;
  }
  .row img{
    width:52px;
    height:52px;
  }
  .row .num{
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .row .unit{
    color: #00DDD3;
  }
  .row .img1{
    width: 40px;
    height: 54px;
  }
  .row .img2{
    width: 38px;
    height: 55px;
  }
  .tag .img3{
    width:20px;
    height:18px;
  }
  .tag .img4{
    width:19px;
    height:19px;
  }
  .tag .img5{
    width:17px;
    height:17px;
  }
  .bg-tag{
    width: 140px;
    height: 38px;
    background-image: url('../../src/assets/img/bg-tag.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #0a99f3;
  }
  .row .name{
    opacity: 0.62;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
  .name-box{
    height:52px;
    display:flex;
    align-items: center;
  }
  .click{
    cursor: pointer;
  }
</style>