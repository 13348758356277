<template>
    <div class="bigBg" :style ="bigBg" style="overflow-y: scroll;background-attachment:local;position: relative;">
    <!-- <div class="big-bg"> -->
      <div class="header">
        <div class="time">
          <img src="../src/assets/img/calender.png" class="calenderImg">
          <div>{{date}}</div>
          <img src="../src/assets/img/time.png" class="timeImg">
          <div>{{time}}</div>
        </div>
        <div class="title"  @click="handleFullScreen">
          <div class="title" :style ="title">
          </div>
        </div>
        <div class="sky">
          <div>{{degree}}°C</div>
          <img src="../src/assets/img/wendu.png" class="Img4">
          <div>{{wind}}</div>
          <img src="../src/assets/img/windSpeed.png" class="Img3">
          <!-- <img src="../src/assets/img/breeze.png" class="Img2"> -->
          <div>{{weather}}</div>
          <img :src="weatherImg" class="Img1">
        </div>
      </div>
      <div class="main">
        <!-- 左侧 -->
        <div class="left">
          <!-- 左一 -->
          <div class="leftBox5">
            <commen head="知社区">
              <frame @drop="getVal" :people="peopleTotal"></frame>
            </commen>
          </div>
        
          <!-- 左二 -->
          <div class="leftBox5">
            <commen head="知网格">
              <frame1 @drop="getVal"></frame1>
              <div class="table3">
                <left1_3 @clickTableRow="handleTableRow1"></left1_3>
              </div>
            </commen>
          </div>
          <!-- <div class="leftBox4">
            <commen head="知人口">
              <div class="table1 pieBox">
                  <div style="flex:1;">
                    <leftCircular></leftCircular>
                  </div>
                  <div style="flex:1;">
                    <leftCircular1></leftCircular1>
                  </div>
              </div>
            </commen>
          </div>
           <div class="leftBox3">
            <commen head="知小区">
               <div class="table3">
                <left1_2></left1_2>
              </div>
            </commen>
          </div> -->
        </div>
        
        <!-- 右侧2/3处 -->
        <div class="middleR">
          <!-- 右上 -->
          <div class="leftTop" style="display:flex">
            <div class="left1" style="position: relative;">
              <myMap ref="map" @clickArea="clickMapArea" @clickText="clickMapArea" @clickPoint="clickMapPoint"></myMap>
              <img src="../src/assets/img/top.png" class="mapTop" style="top:0">
              <img src="../src/assets/img/bottom.png" class="mapBottom">
              <img src="../src/assets/img/left.png" class="mapLeft">
              <img src="../src/assets/img/right.png" class="mapRight">
            </div>
            <div class="right1">
              <commen head="社区治理">
                <div class="chart">
                  <right1Page></right1Page>
                </div>
                <div class="notes">
                  <titleNav name="随手报最新通知"></titleNav>
                </div>
                <div class="table3">
                  <left1_4 @clickTableRow="handleTableRow"></left1_4>
                </div>
              </commen>
            </div>
          </div>
          <!-- 右下 -->
          <div style="display:flex">
            <!-- 右一 -->
            <div class="leftBottom2">
               <commen head="全科网格服务架构" long>
                <div class="big-box">
                  <div class="left-box">
                     <!-- <div style="padding-left:5px">
                      <titleNav name="网格架构"></titleNav>
                    </div> -->
                    <div class="jg">
                      <!-- <img src="../src/assets/img/jg2.png"> -->
                      <vue2-org-tree
                        :data="data"
                        :horizontal="true"
                        :label-class-name="labelClassName"
                        :renderContent="renderContent"
                      />
                   </div>
                   <!-- collapsable -->
                   <!-- @on-expand="onExpand" -->
                    <!-- @on-node-click="NodeClick" -->
                    <!-- @on-node-mouseover="onMouseover"
                        @on-node-mouseout="onMouseout" -->
                  </div>
                  <div class="right-box">
                    <div style="display:flex;flex-wrap: wrap;">
                      <div class="bg zb" v-for="item in party" :key="item.id" @click="chooseParty(item.input1666833756376)">
                        {{item.input1666833756376||''}}
                      </div>
                    </div>
                  </div>
                </div>
               </commen>
            </div>
            <!-- 左一 -->
             <div class="leftBottom1">
              <commen head="知党建">
                <!-- <div class="img-box">
                  <img src="../src/assets/img/jg.png">
                </div> -->
               <div class="left-box">
                  <div class="content">
                    <div class="img-box">
                        <right4 @click="seeParty"></right4>
                    </div>
                  </div>
               </div>
              </commen>
            </div>
          </div> 
        </div>
      </div>
      <!-- 网格架构图弹框 -->
      <div class="bulletBox" v-if="showBox">
        <bulletBox :data="partyData" @close="toClose"></bulletBox>
      </div>
      <!-- 随手报弹框 -->
      <div class="bulletBox" v-if="showBox2">
        <bulletBox1 :id="bulletBoxId" @close="toClose"></bulletBox1>
      </div>
      <!-- 点总人口弹框 -->
      <div class="bulletBox" v-if="showBox3">
        <bulletBox2 @close="toClose" name="人口分布结构">
            <div class="population">
              <div v-for="item in peopleList" :key="item.id" class="population-box">
                <div class="population-title">{{item.input1687834529738+'(人)'||''}}</div>
                <div class="population-num">{{item.input1688435073349 ||0}}</div>
              </div>
            </div>
            <div class="table">
                <div style="flex:1;">
                  <leftCircular></leftCircular>
                </div>
                <div style="flex:1;">
                  <leftCircular1></leftCircular1>
                </div>
            </div>
        </bulletBox2>
      </div>
      <!-- 点网格，点地图区域弹框 -->
      <div class="bulletBox" v-if="showBox4">
        <bulletBox3 @close="toClose" :gridData="gridData"></bulletBox3>
      </div>
      <!-- 便民服务弹窗 -->
      <div class="bulletBox" v-if="showBox5">
        <bulletBox2 @close="toClose" :name="name">
          <textPage :name="name"></textPage>
        </bulletBox2>
      </div>
      <!-- 网格风采弹窗 -->
      <div class="bulletBox" v-if="showBox7">
        <bulletBox2 @close="toClose" name="网格风采">
          <activity id='1'></activity>
        </bulletBox2>
      </div>
      <!-- 总面积弹窗/网格分布 -->
      <div class="bulletBox" v-if="showBox6">
        <bulletBox2 @close="toClose" :showTitle="false">
          <div style="width:100%; height:100%;padding:5% 0 0">
            <el-image
              v-if="name=='总面积'"
              style="width:100%; height:100%;"
              :src="require('../src/assets/img/life.jpg')"
              fit="contain"
              :preview-src-list="[require('../src/assets/img/life.jpg')]"
            >
            </el-image>
            <el-image
              v-if="name=='网格分布图'"
              style="width:100%; height:100%;"
              :src="require('../src/assets/img/grid/grid.jpg')"
              fit="contain"
              :preview-src-list="[require('../src/assets/img/grid/grid.jpg')]"
              >
            </el-image>
          </div>
        </bulletBox2>
      </div>
      <!-- 点击知党建弹窗 -->
      <div class="bulletBox" v-if="showBox8">
        <bulletBox2 @close="toClose" :name="partyName" :short="partyName=='党群活动中心'">
            <pioneer v-if="partyName=='党员先锋岗'"></pioneer>
            <activity id='2' v-if="partyName=='党群活动中心'"></activity>
            <partyMember v-if="partyName=='党员总数'"></partyMember>
            <organize v-if="partyName=='组织架构'"></organize>
            <develop v-if="partyName=='党员发展'"></develop>
        </bulletBox2>
      </div>
      <!-- 点击小区弹框 -->
      <div class="bulletBox" v-if="showBox9" >
        <bulletBox2 @close="toClose" :name="pointName">
          <village :name="pointName"></village>
        </bulletBox2>  
      </div>
      <!-- 网格管理 -->
      <div class="bulletBox" v-if="showBox10" >
        <bulletBox2 @close="toClose" :name="name">
            <el-carousel :interval="3000" arrow="always" indicator-position="outside" height="2.8rem">
                <el-carousel-item v-for="item in gridArr" :key="item">
                  <div  style="width:100%; height:100%;">
                        <el-image
                            style="width: 100%;height:100%;"
                            :src="item"
                            fit="contain"
                            :preview-src-list="gridArr"
                        >
                        </el-image>
                  </div>
                </el-carousel-item>
            </el-carousel>
        </bulletBox2>  
      </div>
    </div>
</template>
<script>
    import moment from 'moment'
    import middleBottom2 from './components/middleBottom2'
    import middleBottom1a from './components/middleBottom1a'
    // import left3 from './components/left3'
    import left3a from './components/left3a'
    import myMap from './components/myMap'
    import right1Page from './right1Page'
    import right2Page from './right2Page'
    // import right3Page from './right3Page'
    import right3Page1 from './right3Page1'
    import right4Page from './right4Page'
    // import left2Page from './left2Page'
    import left2Page1 from './left2Page1'
    // 知社区
    import frame from './components/frame.vue'
    //知网格6个按钮
    import frame1 from './components/frame1.vue'
    import commen from './components/commen.vue'
    import titleNav from './components/titleNav.vue'
    // 知党建
    import right4 from './components/right4.vue'
    import left1_2 from './components/left1_2.vue'
    //知网格表格
    import left1_3 from './components/left1_3.vue'
    //随手报表格
    import left1_4 from './components/left1_4.vue'
    import bulletBox from './components/bulletBox.vue'
    import bulletBox1 from './components/bulletBox1.vue'
    import bulletBox2 from './components/bulletBox2.vue'
    import bulletBox3 from './components/bulletBox3.vue'
    //知人口-结构饼图
    import leftCircular from './components/leftCircular.vue'
    import leftCircular1 from './components/leftCircular1.vue'
    // 便民服务文本弹框
    import textPage from './components/textPage.vue'
    //网格风采
    import activity from './components/activity.vue'
    //先锋岗
    import pioneer from './components/pioneer.vue'
    //党员总数
    import partyMember from './components/partyMember.vue'
    // 组织架构
    import organize from './components/organize.vue'
    // 党员发展
    import develop from './components/develop.vue'
    // 小区弹框内容
    import village from './components/village.vue'
    import {getWeather,getVillage,getGridImage} from '../src/api/api'
    export default{
        // inject:['reload'],
        name:"screen",
        components: { 
            myMap,
            // middleBottom2,
            // middleBottom1a,
            right1Page,
            leftCircular,
            leftCircular1,
            // right2Page,
            // right3Page,
            // right3Page1,
            // right4Page,
            frame,
            frame1,
            commen,
            titleNav,
            right4,
            // left1_2,
            left1_3,
            left1_4,
            bulletBox,
            bulletBox1,
            bulletBox2,
            bulletBox3,
            textPage,
            activity,
            pioneer,
            partyMember,
            organize,
            develop,
            village,
            // left3,
            // left3a,
            // left2Page
            // left2Page1
        },
        mounted(){
          let params = {
            latitude:30.890253,
            longitude:105.387039,
          }
          getWeather(params).then(res=>{
            // console.log(res)
            if(res.data.code===200){
                var data = res.data.data;
                this.degree = data.observe.degree
                this.weather = data.observe.weather_short
                this.wind =data.observe.wind_direction?`风速${data.observe.wind_direction}级`:'微风'
                //根据天气更换图标
                switch(data.observe.weather_short){
                  case '阴':
                    this.weatherImg =require('../src/assets/img/yintian.png')
                    break;
                  case '晴':
                    this.weatherImg =require('../src/assets/img/sunnyDay.png')
                    break;
                  case '雨':
                  this.weatherImg =require('../src/assets/img/yu.png')
                  break;
                  default:
                    this.weatherImg =require('../src/assets/img/yintian.png')
                }
            }
          })
        },
        data() {
            return {
              // note: {
              //   backgroundImage: "url(" + require("../src/assets/img/module.png") + ")",
              //   backgroundRepeat: "no-repeat",
              //   backgroundSize: "100% 100%",
              //   height: '100%',
              //   width: "100%"
              // },
              note: {
                backgroundImage: "url(" + require("../src/assets/img/box/frame.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize:"100% 100%",
                height: '100%',
                width: "100%"
              },
              note1: {
                backgroundImage: "url(" + require("../src/assets/img/box/headLeft1.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize:"100% 100%",
                width: '35%',
              },
              left3: {
                // backgroundImage: "url(" + require("../src/assets/img/left3.png") + ")",
                backgroundImage: "url(" + require("../src/assets/img/left3-bg.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                height: '100%',
                width: "100%"
              },
              bigBg: {
                backgroundImage: "url(" + require("../src/assets/img/big-bg.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                height: '100%',
                width: "100%",
              },
              mapBg:{
                backgroundImage: "url(" + require("../src/assets/img/background.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                height: '100%',
                width: "100%",
              },
              title:{
                backgroundImage: "url(" + require("../src/assets/img/community/title.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 32.8%",
                height: '100%',
                width: "100%",
                marginTop:"5%"
              },
              calender:{
                backgroundImage: "url(" + require("../src/assets/img/calender.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "6.42% 30%",
                height: '100%',
                width: "100%",
                marginTop:"6%",
                marginLeft:"5.8%"
              },
              degree:15,
              weather:'阴',
              wind:'微风',
              date:'',
              time:'',
              weatherImg:require('../src/assets/img/yintian.png'),
              fullscreen:false, //是否全屏
              party:[],
              partyData:{},
              // 网格弹出框传的数据
              gridData:{
                id:null,
                name:null,
              },
              // 随手报弹出框id
              bulletBoxId:0,
              showBox:false,
              showBox2:false,
              showBox3:false,
              showBox4:false,
              showBox5:false,
              showBox6:false,
              showBox7:false,
              showBox8:false,
              showBox9:false,
              showBox10:false,
              pointName:'',
              // 点击的知党建标题
              partyName:'',
              name:null,
              labelClassName: "bg-color-orange",
              basicInfo: { id: null, label: null },
              basicSwitch: false,
              // 网格管理图片
              gridArr:[require('../src/assets/img/grid/gl.jpg'),require('../src/assets/img/grid/gl1.jpg')],
              communityData:[{
                name:'驻辖区单位',
                expand:false
              },{
                name:'小区',
                expand:false
              },{
                name:'学校',
                expand:false
              },{
                name:'餐饮、小吃',
                expand:false
              },{
                name:'酒店',
                expand:false
              },{
                name:'景点',
                expand:false
              },{
                name:'健身活动广场',
                expand:false
              },{
                name:'医院',
                expand:false
              },{
                name:'药店',
                expand:false
              },{
                name:'银行',
                expand:false
              },{
                name:'快递网点',
                expand:false
              },{
                name:'中型超市',
                expand:false
              },{
                name:'便民管家',
                expand:false
              }],
              communityLngLatArr:[],
              peopleList:[],
              peopleTotal:0,
              data: {
                id: 0,
                label: "全科网格构架图",
                style:1.4,
                children: [
                  {
                    id: 2,
                    label: "一级网格",
                    style:1.2,
                    children: [
                      {
                        id: 5,
                        label: "指导员(2)人",
                      },
                      {
                        id: 6,
                        label: "网格长(4)人"
                      },
                      {
                        id: 9,
                        label: "全科网格员(12)人"
                      },
                      {
                        id: 10,
                        label: "警长(1)人"
                      },
                      {
                        id: 11,
                        style:1,
                        label: "街长(2)人"
                      },
                      {
                        id: 13,
                        label: "法律顾问(1)人"
                      }
                    ]
                  },
                  {
                    id: 3,
                    label: "二级网格",
                    style:1.2,
                    children: [
                      {
                        id: 7,
                        label: "小区网格员(17)人"
                      },
                    ]
                  },
                  {
                    id: 4,
                    label: "三级网格",
                    style:1.2,
                    children: [
                      {
                        id: 8,
                        label: "楼栋长(136)人"
                      },
                    ]
                  }
            ]
          },
            }
        },
        created () {
          this.getParty({})
          this.getVillageData()
          setInterval(() =>{
            this.date = this.dateFormat(new Date())
            this.time = this.dateFormatTime(new Date())
          }, 1000)
        },
        methods: {
          //获取18个小区数据
          getVillageData(){
            getVillage({
              formKey: "RW5txIHC",
              queryId: "92",
              queryParams: {
                select1688450430067label: "蟠龙社区"
                },
              size: 100,
              current: 0
            }).then(res=>{
              if(res.data.code==200){
                this.peopleList=res.data.data.rows
                this.peopleTotal=this.peopleList.reduce((sum, obj) => sum + Number(obj.input1688435073349), 0)
              }
              // console.log(res,'18个小区信息')
            })
          },
          //点击地图面积事件
          clickMapArea(e){
            this.gridData.name=e.target.name
            this.showBox4=true
            // console.log(e)
          },
          //点击小区标记点事件
          clickMapPoint(e){
            if(e.target.name&&e.target.name.match(/\d+/g)){
              this.pointName=e.target.name.substring(0,e.target.name.indexOf(e.target.name.match(/\d+/g)[0]))
               this.showBox9=true
            }else{
              this.pointName=e.target.name
              this.showBox9=true
            }
            // console.log(e,'点击小区标记点')
          },
          //点击知网格表格行
          handleTableRow1(row){
            // console.log(row,'行')
            this.gridData.name=row.input1686813778257
            this.showBox4=true
          },
          //点击随手报表格行
          handleTableRow(row){
            // console.log(row,'行')
            this.bulletBoxId=row.id
            // console.log(this.$refs.table2)
            this.showBox2=true
          },
          //点击知党建
          seeParty(name){
            this.partyName=name
            this.showBox8=true
          },
          //点击知社区
          getVal(name){
            // console.log(name)
            if(name=='总人口'){
              this.getVillageData()
              this.showBox3=true
            }else if(name=='全科网格服务'){
                return
            }else if(name=='便民管家'||name=='网格员管理制度'||name=='全科网格员职能'||name=='网格员考核制度'){
                this.name=name
                this.showBox5=true
            }else if(name=='总面积'||name=='网格分布图'){
                this.name=name
                this.showBox6=true
            }else if(name=='网格管理'){
                this.name=name
                this.showBox10=true
            }else if(name=='网格风采'){
                this.showBox7=true
            }else{
              this.communityData.map(item=>{
                if(item.name==name){
                  item.expand=!item.expand
                }
              })
              // 点开一个按钮，该按钮展开，再点一次清除，所有按钮点击一边可以看全部
              this.communityData.filter(item=>item.name==name)[0].expand?
              getVillage( {
                  formKey: "ymygnAao",
                  queryId: "96",
                  queryParams: {
                    select1687931663500label: "蟠龙社区",
                    input1687931710516: name,
                  },
                  size: 500,
                  current: 0
                }).then(res=>{
                  // console.log(res,name,'等等')
                  this.communityLngLatArr=this.communityLngLatArr.concat(res.data.data.rows)
                  // console.log(this.communityLngLatArr,'communityLngLatArr')
                  this.$refs.map.addMarker(res.data.data.rows,name)
              }):this.remove(name)
            }
           
                       
          },
          remove(name){
              this.$refs.map.removeMarkers(this.communityLngLatArr.filter(item => item.input1687931710516==name).map(item=>JSON.parse(item.input1687931763483)))
              this.communityLngLatArr=this.communityLngLatArr.filter(item => item.input1687931710516!=name);
          },
          //渲染节点
        renderContent(h, data) {
          //  console.log(data,'渲染')
           let fontSize=`${'font-size:'+data.style+'em'}`
            return (
              <p>{data.label}</p>
            );
        },
          //鼠标移出
          // onMouseout(e, data) {
          //   this.basicSwitch = false;
          // },
          //鼠标移入
          // onMouseover(e, data) {
          //   this.basicInfo = data;
          //   this.basicSwitch = true;
          //   var floating = document.getElementsByClassName("floating")[0];
          //   floating.style.left = e.clientX + "px";
          //   floating.style.top = e.clientY + "px";
          // },
          //点击节点
          // NodeClick(e, data) {
          //   console.log(e, data);
          // },
          //默认展开
          toggleExpand(data, val) {
            if (Array.isArray(data)) {
              data.forEach(item => {
                this.$set(item, "expand", val);
                if (item.children) {
                  this.toggleExpand(item.children, val);
                }
              });
            } else {
              this.$set(data, "expand", val);
              if (data.children) {
                this.toggleExpand(data.children, val);
              }
            }
          },
          collapse(list) {
            list.forEach(child => {
              if (child.expand) {
                child.expand = false;
              }
              child.children && this.collapse(child.children);
            });
          },
          //展开
          onExpand(e, data) {
            if ("expand" in data) {
              data.expand = !data.expand;
              if (!data.expand && data.children) {
                this.collapse(data.children);
              }
            } else {
              this.$set(data, "expand", true);
            }
          },
          chooseParty(name){
              // this.getParty({
              //   input1666833756376:name,
              // })
            this.getImg(name)
            this.showBox=true
          },
          toClose(){
            this.showBox=false
            this.showBox2=false
            this.showBox3=false
            this.showBox4=false
            this.showBox5=false
            this.showBox6=false
            this.showBox7=false
            this.showBox8=false
            this.showBox9=false
            this.showBox10=false
          },
          getImg(name){
            getGridImage({
              gridName:name
            }).then(res=>{
              this.partyData=res.data.data.data[0]
              // console.log(res,'网格')
            })
          },
          getParty(params){   
            getVillage({
              current: 0,
              formKey:"qpVU59G5",
              queryId:"77",
              queryParams: {
                  select1666836903789label:"蟠龙社区",
                  ...params
              },
              size: 100,
            }).then(res=>{
              // console.log(res,'党建')
              if(params.input1666833756376){
                this.partyData=res.data.data.rows[0]||{}
              }else{
                 this.party=res.data.data.rows
              } 
            })
          },
          dateFormat(date) {
            return moment(new Date(date).getTime()).format('YYYY-MM-DD');
          },
          dateFormatTime(date) {
            return moment(new Date(date).getTime()).format('HH:mm:ss');
          },
          // 全屏事件
          handleFullScreen() {
            let element = document.documentElement;
            // 判断是否已经是全屏
            // 如果是全屏，退出
            if (this.fullscreen) {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
              }
              // console.log('已还原！');
            } else {    // 否则，进入全屏
              if (element.requestFullscreen) {
                element.requestFullscreen();
              } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
              } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
              }
              // console.log('已全屏！');
            }
            // 改变当前全屏状态
            this.fullscreen = !this.fullscreen;
          }
        }
    }
</script>
<style scoped>
   @import  "../src/assets/css/screen.less";

   .headerText{
    color: aliceblue;font-size: 30px;
   }
   ::-webkit-scrollbar {
    /* 隐藏滚动条*/
    display: none;
  } 
  .mapTop{
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 660px;
  }
  .mapBottom{
    width: 100%;height: 45px;
    position: absolute;
    bottom: 0px;
  }
  .mapLeft{
    width: 25px;
    height:500px;
    position: absolute;
    left: -1px;
    bottom: 44px;
  }
  .mapRight{
    width: 25px;
    height:500px;
    position: absolute;
    right: 0px;
    bottom: 45px;
  }
  .bg{
    background-image: url("../src/assets/img/box/gk-bg.png");
    background-repeat:no-repeat;
    background-size:100% 100%;
    width: 126px;
    height: 52px;
    color:#fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .zb{
    width: 30%;
    height: 42px;
    font-size: 18px;
    font-weight: 500;
    margin-right:10px;
    margin-bottom:25px;
  }
  .zb:nth-of-type(3n){
    margin-right:0;
  }
  .zb:nth-last-of-type(-n+3){
    margin-bottom:0;
  }
  .zb:hover{
    cursor:pointer;
  }
  .bulletBox{
    position: fixed;
    width:100vw;
    height:100vh;
    background-image: url('../src/assets/img/box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bulletBox .table{
    width:100%;
    height:40%;
    margin-top:25px;
    display: flex;
  }
  .bulletBox .population{
    height:60%;
    display: flex;
    flex-wrap: wrap;
  }
  .population-title{
    font-size: 16px;
    color: #fefefe;
    letter-spacing: 1.22px;
  }
  .population-box{
    margin-right:17px;
  }
  /* .population-box:nth-of-type(5n){
    margin-right: 0;
  } */
  .population-num{
    background-image: url('../src/assets/img/box/renkouBox.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width:140px;
    height: 41px;
    line-height: 41px;
    font-size: 18px;
    color: #0a99f3;
    letter-spacing: 1.57px;
    text-align: center;
    font-weight: bold;
  }
  .main .leftBox4 .pieBox{
    display:flex;
    padding-top:20px;
  }
.org-tree-container{
  padding:0;
  background: transparent;
}
::v-deep .org-tree-node-label-inner{
  background: #102E6D;
  color:#fff;
  font-size: 14px;
  padding:6px 8px;
}
::v-deep .horizontal .org-tree-node-children > .org-tree-node{
padding-top:4px;
padding-bottom:2px;
}
::v-deep .horizontal .org-tree-node:only-child:before{
  top:0
}
</style>