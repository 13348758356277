<template>
    <div class="box">
        <div style="width: 100%;">
            <table
            style="width: 100%;height:20px;text-align: center;color: white;;position: sticky;top: -2px;border-bottom: 1px solid #003a87;border-top:1px solid #003a87;overflow: scroll;background-color: #102e6e;">
            <thead>
                <tr>
                    <td width="16.6%"></td>
                    <td width="16.6%">排查人员</td>
                    <td width="16.6%">居家隔离</td>
                    <td width="16.6%">健康监测</td>
                    <td width="16.6%">疑似病例</td>
                    <td width="16.6%">确诊病例</td>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
        <table id="table1"
        style="width:100%;text-align: center;color: white;z-index: 100;overflow: scroll;margin-top: 0px;line-height:20px;overflow: hidden;" rules="rows">
            <thead></thead>
            <tbody id="ttbody">

            </tbody>
        </table>
    </div>
    </div>
</template>


<script>

import {getEpidemicRegistration2} from '../../src/api/api'

export default {
    name: 'warehouse-manage',
    data() {
      return {
        // tableData:1,
      }
    },
    mounted() {
        this.getData()
        const timer = setInterval(() => {
            // console.log("每10min刷新一次 -- 疫情登记信息")
            this.getData()
        }, 600 * 1000);
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once('hook:beforeDestroy', () => {
            clearInterval(timer);
        })
    },
    methods: {
        scroll1(){
          var marginTop = 0;
          var time1 = setInterval(function () {
              var p11 = document.getElementById("table1");
              if (marginTop <= (-490)) {
                  p11.style.marginTop = 0 + "px"
                  marginTop = 0
              } else {
                  p11.style.marginTop = (marginTop -= 1) + "px"
                  p11.style.animationTimingFunction = 'linear'
              }
          }, 50)
          // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
          this.$once('hook:beforeDestroy', () => {
              clearInterval(time1);
          })
      },
        getData() {
            let params = {
                customerId: 5,
            };
            getEpidemicRegistration2(params).then(res => {
                // console.log("疫情登记信息列表")
                // console.log(res.data)
                // console.log(res.data.data.list)
                var data = res.data.data.list
                var html = ""
                for (var i = 0; i < data.length; i++) {
                    if (i % 2 == 0) {
                        html += "<tr height='5px'><td width='16.6%'><div style='width:100%;height:20px;overflow:hidden'>" + data[i].buildingName + "</div></td><td width='16.6%'>" + data[i].person1 + "</td><td width='16.6%'>" + data[i].person5
                            + "</td><td width='16.6%'>" + data[i].person4 + "</td><td width='16.6%'>" + data[i].person3 + "</td><td width='16.6%'>" + data[i].person2 + "</td></tr>"
                    } else {
                        html += "<tr height='5px' style='background:#102e6e;'><td><div style='width:100%;height:20px;overflow:hidden;'>" + data[i].buildingName + "</div></td><td>" + data[i].person1 + "</td><td>" + data[i].person5 +
                            "</td><td>" + data[i].person4 + "</td><td>" + data[i].person3 + "</td><td>" + data[i].person2 + "</td></tr>"
                    }
                }
                // console.log(html)
                var p = document.getElementById("ttbody");
                p.innerHTML = html;
                this.scroll1()
            }).catch(err => {
                // console.log(err)
            })
        }
    }
  }
</script>
<style lang="less" scoped>
.box{
    width: 100%;height: 100%;
    /* display: flex; */
    /* display: inline-flex; */
}

#ttbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 10px;
}




</style>

