<template>
  <div
      style="width:100%;height:100%"
  >
    <div class="population">
        <div class="population-box">
            <div class="population-title">
                <img src="../../src/assets/img/community/ld.png" class="img img1">
                <span>楼栋数（个）</span>
            </div>
            <div class="population-num">{{data.number1686019234924||0}}</div>
        </div>
        <div class="population-box">
            <div class="population-title">
                <img src="../../src/assets/img/community/unit.png" class="img img2">
                <span>单元数（个）</span>
            </div>
            <div class="population-num">{{data.number1686020541195||0}}</div>
        </div>
        <div class="population-box">
            <div class="population-title">
                <img src="../../src/assets/img/community/cz.png" class="img img3">
                <span>常住户数（户）</span>
            </div>
            <div class="population-num">{{data.number1686020199623||0}}</div>
        </div>
        <div class="population-box">
           <div class="population-title">
                <img src="../../src/assets/img/community/cz.png" class="img img3">
                <span>人口数（人）</span>
            </div>
            <div class="population-num">{{data.number1686020177458||0}}</div>
        </div>
    </div>
     <el-carousel :interval="3000" arrow="hover" indicator-position="none" height="2.4rem" v-if="data.image_upload1688626337607&&data.image_upload1688626337607.length>0">
            <el-carousel-item v-for="item in data.image_upload1688626337607" :key="item.id">
              <el-image
                  style="width: 100%;height:100%;"
                  :src="item.url"
                  fit="contain"
                  :preview-src-list="[item.url]"
              >
              </el-image>
            </el-carousel-item>
        </el-carousel>
  </div>
</template>

<script>
import {getVillage} from '../../src/api/api'
export default {
    name:"village",
    props: {
      name:{
          type:String,
          default:''
      }
		},
    data(){
        return{
            data:{},
        }
    },
    created () {
        this.getData(this.name)
    },
    mounted() {
    },
    destroyed(){
    },
    methods: {
        getData(name){
            getVillage({
              formKey: "t362qQ2O",
              queryId: "73",
              queryParams: {
                input1677569925469: name
              },
              size: 100,
              current: 0
            }).then(res=>{
                this.data=res.data.data.rows[0]||{}
                // console.log(res,'小区')
            })
        }
        
    }

}
</script>

<style scoped>
 .population{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32.5px;
  }
  .population-title{
    font-size: 16px;
    color: #fefefe;
    letter-spacing: 1.22px;
    margin-bottom: 4.5px;
    display: flex;
    align-items: center;
  }
  .population-box{
    margin-right:17px;
  }
  .img{
    vertical-align: middle;
    margin-right: 6.5px;
  }
  .img1{
    width: 24px;
    height: 24px; 
  }
  .img2{
    width: 22.5px;
    height: 23px;
  }
  .img3{
    width: 27px;
    height: 22.5px;
  }
  .population-box:nth-of-type(4n){
    margin-right: 0;
  }
  .population-num{
    background-image: url('../../src/assets/img/box/renkouBox.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width:140px;
    height: 41px;
    line-height: 41px;
    font-size: 18px;
    color: #0a99f3;
    letter-spacing: 1.57px;
    text-align: center;
    font-weight: bold;
  }
</style>