import { render, staticRenderFns } from "./bulletBox2.vue?vue&type=template&id=1e9e9050&scoped=true&"
import script from "./bulletBox2.vue?vue&type=script&lang=js&"
export * from "./bulletBox2.vue?vue&type=script&lang=js&"
import style0 from "./bulletBox2.vue?vue&type=style&index=0&id=1e9e9050&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e9e9050",
  null
  
)

export default component.exports