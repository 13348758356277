<template>
  <div class="pioneer-page"
        v-loading="loading"  
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)"
  >
    <div v-for="item in pioneerList" :key="item.id" class="pioneer-box" >
        <el-image
            class="img"
            :src="item.image_upload1688541667491&&item.image_upload1688541667491[0]&&item.image_upload1688541667491[0].url?item.image_upload1688541667491[0].url:require('../../src/assets/img/tou.png')"
            fit="contain"
            :preview-src-list="item.image_upload1688541667491.map(res=>res.url)"
        >
        </el-image>
        <div class="text">
            {{item.input1688541646439||''}}
        </div>
    </div>
  </div>
</template>

<script>
import {getVillage} from '../../src/api/api'
export default {
    name:"pioneer",
    data(){
        return{
            pioneerList:[],
            loading:true
        }
    },
    created () {
        this.getData()
    },
    mounted() {
    },
    destroyed(){
    },
    methods: {
        getData(){
            getVillage({
                formKey: "NAcwaL4v",
                queryId: "102",
                queryParams: {
                    select1688541625967label: "蟠龙社区"
                },
                size:500,
                current: 0
            }).then(res=>{
                this.loading=false
                this.pioneerList=res.data.data.rows||[]
                // console.log(res,'先锋岗')
            })
        }
        
    }

}
</script>

<style scoped>
.pioneer-page{
    width: 100%;
    height:100%;
    background: #e8eff9;
    border-radius: 4px;
    overflow: scroll;
    padding:30px 28px;
}
.pioneer-page .pioneer-box{
    display: inline-block;
    width:118px;
    height:180px;
    margin-right:30px;
    margin-bottom: 20px;
}
.pioneer-page .pioneer-box:nth-of-type(5n){
    margin-right:0;
}
.pioneer-page .img{
    width:118px;
    height:150px;
}
.pioneer-page .text{
    width:100%;
    text-align: center;
    font-size: 18px;
    color: #031d3b;
}
</style>