<template>
  <div class="com-container" >
      <div class="com-chart" ref="right1_ref"></div>
  </div>
</template>

<script>
  import { population,getType } from '../../src/api/api'
  import * as echarts from "echarts";
  export default{
      name:"leftCircular1",
      data() {
          return {
              chartInstance:null,
          };
      },
      mounted() {
        this.getData()
        // const timer = setInterval(() => {
        //   console.log("每10min刷新一次")
        //   this.getData()
        // }, 600 * 1000);
        // // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        // this.$once('hook:beforeDestroy', () => {
        //   clearInterval(timer);
        // })
      },
      destroyed(){
 
      },
      methods: {
          //初始化echartInstance对象
          initChart(data){
              this.chartInstance = this.$echarts.init(this.$refs.right1_ref)
              let primarySchool=data.primarySchool||0
              let middleSchool=data.middleSchool||0
              let highSchool=data.highSchool||0
              let university=data.university||0
              let sum=primarySchool+middleSchool+highSchool+university
              //对图表初始化配置的控制
              const initOption = {
                tooltip: {
                  // trigger: 'item'
                },
                // color:['#1ed94f','#f2f047','#F5814C','#ffc2a1'],
                //  color: [
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#FC6679 " },
                //         { offset: 1, color: "#FDC581 " }
                //     ]),
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#f2f047" },
                //         { offset: 1, color: "#1ed94f" }
                //     ]),
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#E7B115" },
                //         { offset: 1, color: "#F5814C" }
                //     ]),
                //     // new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //     //     { offset: 0, color: "#ffc2a1" },
                //     //     { offset: 1, color: "#ffb1bd" },
                //     // ]),
                // ],
                legend: {
                  orient: "vertical",
                  x: 'left', //可设定图例在左、右、居中
                  y: 25,
                  left:10,
                  textStyle: {
                    color: '#FFFFFF'
                  }
                },
                title :{
                  text :'学历结构',
                  textStyle:{
                    color:"#fff",
                    fontSize:16,
                    align:"left",
                  },
                  subtext :'',
                  x:'10px',
                },
                grid: {
                  left: 0,
                  right: 0,
                  bottom: '0',
                  // containLabel: true,
                  width: '60%',
                  height: "90%",
                },
                series: [{
                    type: 'pie',
                    // roundCap:true,
                    // itemStyle: {
                    //     borderRadius: 60, // 设置每一段子项目的圆角
                    //     borderWidth: 10, // 子项目间距
                    // },
                    radius:'50%',
                    center:['60%',"50%"],
                    // radius: ['45%', '60%'],
                    // center:['70%',"50%"],
                    label:{
                      show:false
                    },
                    labelLine: {
                      show: false
                    },
                    data: [
                      {name:`小学${sum!=0?(primarySchool*100/sum).toFixed(1):0}%`,value:primarySchool},
                      {name:`初中${sum!=0?(middleSchool*100/sum).toFixed(1):0}%`,value:middleSchool},
                      {name:`高中${sum!=0?(highSchool*100/sum).toFixed(1):0}%`,value:highSchool},
                      {name:`大专及以上${sum!=0?(university*100/sum).toFixed(1):0}%`,value:university},
                    ]
                  }]
              }
              this.chartInstance.setOption(initOption)
          },
          getData() {
            population({
                formKey:"Fj9XoSvY",
            }).then(res=>{
                // console.log(res,'人口')
                this.initChart(res.data.data)
            })
          }
      },

  }
</script>