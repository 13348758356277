<template>
  <div id="box">
      <div class="tableBox">
          <table style="width: 100%;text-align: center;border-color:#003a87;border-style:solid;color: white;z-index: 10000;position: sticky;background-color: #010a1f;top: -2px;" border="1" rules="all">
            <thead class="tableHead">
              <tr>
                <td width="18.5%">机关干部</td>
                <td width="9%">网格颜色</td>
                <td width="17%">社区两委</td>
                <td width="17%">组长</td>
                <td width="17%">网格员</td>
                <td width="10%">人数</td>
                <td>境外返乡人员</td>
              </tr>
            </thead>      
            <tbody></tbody>
          </table>
          <table id="table" style="width: 100%;text-align: center;border-color:#003a87;border-style:solid;color: white;z-index: 100;overflow: scroll;margin-top: 0px;" border="1" rules="all">
              <thead></thead>      
              <tbody id="tbody">
  
              </tbody>
          </table>
      </div>

      <div class="foot">驻社区领导：<span class="name">甘俊磊</span>13880655321</div>
  </div>
</template>



<script>
import {getGrid} from '../../src/api/api'
export default {
  name: 'warehouse-manage',
  data() {
    return {

    }
  },
  mounted(){
    this.getDataInit()
    const timer = setInterval(() => {
      // console.log("每10min刷新一次 -- 网格防控")
      this.getDataInit()
    }, 600 * 1000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer);
    })
  },

  methods: {
      scroll(){
          var marginTop = 0;
          var time = setInterval(function () {
              var p1 = document.getElementById("table");
              if (marginTop <= (-490)) {
                  p1.style.marginTop = 0 + "px"
                  marginTop = 0
              } else {
                  p1.style.marginTop = (marginTop -= 1) + "px"
                  p1.style.animationTimingFunction = 'linear'
              }
          }, 50)
          // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
          this.$once('hook:beforeDestroy', () => {
              clearInterval(time);
          })
      },
      
    getDataInit() {
      let params = {
        customerId: 5,
      };
      getGrid(params).then(res => {
        // console.log("网格防控")
        // console.log(res.data.data)
        // console.log(res.data.data.cadresList)
        // console.log(res.data.data.communityLeader)
        // var data = this.listData
        var data = res.data.data.cadresList
        //
        var html = ""
        for (var i = 0; i < data.length; i++) {
          html += "<tr><td width='18.5%' rowspan=" + data[i].list.length + "><div>" + data[i].name + "</div><div class='telphone'>" + data[i].tel + "</div></td>"
          for (var j = 0; j < data[i].list.length; j++) {
            var list = data[i].list
            if (j == 0) {
              html += "<td width='9%' style='background:" + list[j].colour + ";'>" + list[j].name + "</td><td width='17%' style='height:40px;'" + ";'><div>" + list[j].communityTwoCommittees.name + "</div><div  class='telphone'>" + list[j].communityTwoCommittees.tel +
                "</div></td><td width='17%'><div>" + list[j].teamLeaders[0].name + "</div><div class='telphone'>" + list[j].teamLeaders[0].tel + "</div></td><td width='17%'><div>" +
                list[j].gridMember.name + "</div><div class='telphone'>" + list[j].gridMember.tel + "</div></td><td width='10%'>" +
                list[j].personNum + "<span class='person'>" + "人" + "</span>" + "</td><td>" + list[j].highRiskPersonNum + "</td></tr>"
            } else {
              html += "<tr style='height:40px;'" + "><td style='background:" + list[j].colour + ";'>" + list[j].name + "</td><td><div>" + list[j].communityTwoCommittees.name + "</div><div calss='telphone' style='color:#88b1d0'>" + list[j].communityTwoCommittees.tel + "</div></td><td><div>" +
                list[j].teamLeaders[0].name + "</div><div calss='telphone' style='color:#88b1d0'>" + list[j].teamLeaders[0].tel + "</div></td><td><div>" +
                list[j].gridMember.name + "</div><div class='telphone'>" + list[j].gridMember.tel + "</div></td><td>" + list[j].personNum + "<span class='person'>" + "人" + "</span>" + "</td><td>" + list[j].highRiskPersonNum + "</td></tr>"
            }

          }
        }
        var p = document.getElementById("tbody");
        p.innerHTML = html;
        //
        this.scroll()




      }).catch(err => {
        // console.log(err)
      })
    }
  }
}
</script>
<style>
  .tableBox{
    overflow: hidden;max-height: 355px;
  }
  td{
    /* border: 1px solid red; */
    border-color: #003a87;
  }
  .tableHead{
    height: 40px;
    color: #88b1d0;
    position: sticky;background-color: #010a1f;top: -2px;
  }
  .foot{
    color: #88b1d0;
    font-size: 12px;
    margin-top: 8px;
  }
  .foot .name{
    color: white;
    font-size: 12px;
    margin-right: 8px;
  }
  .telphone{
    font-size: 10px !important;
    color: #88b1d0;
  }
  .person{
    font-size: 12px;
  }
</style>
