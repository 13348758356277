<template>
    <div class="com-container" >
        <div class="com-chart" ref="right3a_ref"></div>
    </div>
</template>

<script>
    import {getEpidemicCompared} from '../api/api'
    import {guanliData} from '../api/api'
    export default{
        name:"right3a",
        data() {
            return {
                chartInstance:null,
                allData:null,    //服务器返回的数据

            };
        },
        mounted() {
            // this.getData()
            this.initChart()
            const timer = setInterval(() => {
                console.log("每隔10分钟请求一次")
                this.initChart()
            }, 600000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            })
        },
        destroyed(){

        },
        methods: {
            //初始化echartInstance对象
            initChart(){
                this.chartInstance = this.$echarts.init(this.$refs.right3a_ref)

                let params = {
                    customerId: 5,
                };
                guanliData(params).then(res => {
                    console.log("res")
                    console.log(res)
                    var data = res.data.values
                    var twoMonthEveryDayPerson = data.twoMonthEveryDayPerson
                    var twoMonthEveryDayPersonByType1 = data.twoMonthEveryDayPersonByType1
                    var twoMonthEveryDayPersonByType3 = data.twoMonthEveryDayPersonByType3
                    var twoMonthEveryDayRemovePerson = data.twoMonthEveryDayRemovePerson
                    var xdates = data.xdates
                    //对图表初始化配置的控制
                    const initOption = {
                        tooltip: {//自定义tip
                            show: true,
                            trigger: 'axis',
                            // show: "true",   //default true
                            showDelay: 0,//显示延时，添加显示延时可以避免频繁切换
                            hideDelay: 50,//隐藏延时
                            transitionDuration: 0,//动画变换时长
                            backgroundColor: 'rgba(0,0,0,0.7)',//背景颜色（此时为默认色）
                            borderRadius: 8,//边框圆角
                            padding: 5,    // [5, 10, 15, 20] 内边距
                            position: function (p) {
                                return [p[0] + 10, p[1] - 10];
                            },
                            formatter: function (params, ticket, callback) {
                                // var tip = "时间" + ' : ' + params[0].name + "<br/>";
                                var tip = ""
                                for (var i = 0, l = params.length; i < l; i++) {
                                    tip += params[i].marker + params[i].seriesName + ' : ' + params[i].value + '<br/>';//鼠标悬浮显示的字符串内容
                                }
                                //同步加载
                                return tip;
                                //异步加载
                                // setTimeout(function () {
                                //     // 仅为了模拟异步回调
                                //     callback(ticket, tip);
                                // }, 10);//延时时间ms
                                // return 'loading...';
                            }
                        },
                        legend: {
                            data: ['重点人群', '居家隔离', '健康监测', '今日解控'],
                            orient: "vertical",
                            y:"center",
                            right: 0,
                            itemWidth: 15,
                            itemHeight: 10,
                            textStyle: {
                                color: '#8296b2'
                            }
                        },
                        color: ['#EE6666', '#FF9242', '#5099FF', '#47D23A'],
                        grid: {
                            left: 0,
                            right: '0%',
                            bottom: '0',
                            containLabel: true,
                            width:'81%',
                            height:"90%",
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            axisLabel: {
                                color: "#569ccb"
                            },
                            axisPointer: {
                                type: 'line',
                                snap: true,
                                lineStyle: {
                                    color: '#193463',
                                    width: 2
                                },
                                handle: {
                                    show: true,
                                    color: '#8296b2',
                                    size: 0
                                }
                            },
                            splitLine: {
                                show: false//x分格线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#163554"
                                }
                            },
                            data: xdates

                        },
                        yAxis: {
                        type: 'value',
                        axisLabel: {
                            color: "#569ccb"
                        },
                        splitLine: {
                            show: true,//x分格线
                            lineStyle:{
                                color:'#0e274c'
                            }
                         }
                    },
                        series: [{
                            name: '重点人群',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle', // 拐点类型
                            symbolSize: 0, // 拐点圆的大小
                            itemStyle: {
                                normal: {
                                    color: '#EE6666', // 折线条的颜色
                                    borderColor: '#EE6666', // 拐点边框颜色
                                    areaStyle: {
                                        type: 'default',
                                        opacity: 0.1
                                    }
                                }
                            },
                            data: twoMonthEveryDayPerson
                        }, {

                            name: '居家隔离',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle',
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: '#FF9242',
                                    borderColor: '#FF9242',
                                    areaStyle: {
                                        type: 'default',
                                        opacity: 0.1
                                    }
                                }
                            },
                            data: twoMonthEveryDayPersonByType1
                        }, {

                            name: '健康监测',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle',
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: '#5099FF',
                                    borderColor: '#5099FF',
                                    areaStyle: {
                                        type: 'default',
                                        opacity: 0.1
                                    }
                                }
                            },
                            data: twoMonthEveryDayPersonByType3
                        }, {
                            name: '今日解控',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle',
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: '#47D23A',
                                    borderColor: '#47D23A',
                                    areaStyle: {
                                        type: 'default',
                                        opacity: 0.1
                                    }
                                }
                            },
                            data: twoMonthEveryDayRemovePerson
                        }]
                    }
                    this.chartInstance.setOption(initOption)

                }).catch(err => {
                    console.log(err)
                })

            },
        },

    }
</script>