<template>
    <div id="titleNav">
        <div style="display: flex;align-items: center;position: absolute;bottom:9px;left:19px">
            <img src="../../src/assets/img/title1.png" style="width:19px;height:21px;">
            <span class="name">{{name}}</span>
            <img src="../../src/assets/img/title2.png" style="width:30px;height:14px;">
        </div>
    </div>
</template>
<script>
export default {
        name:"titleNav",
        props: {
			name:{
				type: String,
				default: "介绍"
			},
		},
        data() {
            return {
                
            }
        },
        mounted() {
        },
        destroyed(){
 
        },
        methods: {

        }
}
</script>

<style scoped>
#titleNav{
    width:90%;
    height: 20px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-image: linear-gradient(0deg, #00d7f0 0%, rgba(83,118,247,0.66) 44%, rgba(255,255,255,0.23) 100%) 1 1;
    position: relative;
    margin-left:5%;
}
.name{
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 2px;
    margin:0 37px 0 9px;
}
</style>