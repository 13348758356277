<template>
    <div class="dj">
       <div class="dj-box dj1" @click="seeBulletBox('组织架构')">
            <div class="dj-bg">
                <!-- <div class="dj-text">{{party.JiaGou||0}}个</div> -->
                <div class="dj-text">{{party.JiaGou||3}}级</div>
                <div class="dj-title">组织架构</div>
            </div>
            <!-- <div>组织架构</div> -->
       </div>
       <div class="dj-box dj2" @click="seeBulletBox('党群活动中心')">
            <div class="dj-bg">
                <div class="dj-text">{{party.HuoDong||4}}个</div>
                <div class="dj-title">党群活动中心</div>
            </div>
            <!-- <div>党群活动中心</div> -->
       </div>
       <div class="dj-box dj5" @click="seeBulletBox('党员总数')">
            <div class="dj-bg">
                <div class="dj-text">{{party.DangYuan||0}}人</div>
                <div class="dj-title">党员总数</div>
            </div>
            <!-- <div>党员总数</div> -->
       </div>
       <div class="dj-box dj4" @click="seeBulletBox('党员先锋岗')">
            <div class="dj-bg">
                <div class="dj-text">{{party.XianFen||4}}个</div>
                <div class="dj-title">党员先锋岗</div>
            </div>
            <!-- <div>党员先锋岗</div> -->
       </div>
        <div class="dj-box dj3" @click="seeBulletBox('党员发展')">
            <div class="dj-bg">
                <div class="dj-text">{{party.FaZhan||4}}人</div>
                <!-- <div class="dj-text">{{party.FaZhan||0}}个</div> -->
                <div class="dj-title">党员发展</div>
            </div>
            <!-- <div>党员发展</div> -->
       </div>
    </div>
</template>
<script>
import {getParty} from '../../src/api/api'
export default {
        name:"right4",
        data() {
            return {
                party:{
                    
                }
            }
        },
        created(){
            this.getData()
        },
        mounted() {

        },
        destroyed(){
 
        },
        methods: {
            getData(){
                getParty().then(res=>{
                    this.party=res.data.data
                    // console.log(res)
                })
            },
            seeBulletBox(name){
                this.$emit('click',name)
            }
        }
}
</script>
<style scoped>
    .dj{
        width:99%;
        height:99%;
        position: relative;
        color:#fff;
        font-size: 16px;
        text-align: center;
    }
    .dj-box{
        width:33%;
        height:48%;
        position:absolute;
        cursor: pointer;
    }
    .dj-text{
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        color: #e26453;
        position: absolute;
        bottom:46%;
        left:0;
        width:100%;
    }
    .dj-title{
        position: absolute;
        bottom:9%;
        left:0;
        width:100%;
    }
    .dj-bg{
        background-repeat:no-repeat;
        background-size:100% 100%;
        height:80%;
        width:100%;
        position:relative;
        background-image: url('../../src/assets/img/party/dj.png');
    }
    .dj1{
        top:0;
        left:0;
    }
    .dj1-bg{
        background-image: url('../../src/assets/img/party/dj6.png');
    }
    .dj2{
       top:0;
        right:0
    }
    .dj2-bg{
        background-image: url('../../src/assets/img/party/dj8.png');
    }
    .dj3{
        bottom:0;
        left:0
    }
    .dj3-bg{
        background-image: url('../../src/assets/img/party/dj7.png');
    }
    .dj4{
        bottom:0;
        right:0
    }
    .dj4-bg{
        background-image: url('../../src/assets/img/party/dj9.png');
    }
    .dj5{
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    .dj5-bg{
        background-image: url('../../src/assets/img/party/dj10.png');
    }
</style>