<template>
    <el-table
        :data="tableData"
        height="100%"
        style="width:100%;font-size:0.875em"
        :row-style="{height:'1.875em'}"   
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#102e6d',color:'#fff',width:'100%',height:'1.875em',}"
        ref="rolltable"
        @row-click="handleTableRow"
        @mouseenter.native="stopScroll"
        @mouseleave.native="startScroll"
    >
        <el-table-column
            prop="title"
            label="时间"
            :width="width*3"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.createTime.slice(0,-3)||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="上报人"
            :width="width*2"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.data.input1669709598535||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="上报内容"
            :show-overflow-tooltip="true"
            :width="width*3"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.data.textarea1669626440853||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="处理状态"
            :width="width*2"
        >
            <template slot-scope="scope">
                <div>
                    {{getStatus(scope.row.status)}}
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import {report} from '../../src/api/api'
let rolltimer
export default {
        name:"left1_4",
        data() {
            return {
                width:40,
                tableData: [],
            }
        },
        created(){
            this.getData()
        },
        mounted(){
            this.tableScroll(false)
            this.$nextTick(() => {
                // console.log(this.$refs.rolltable.$el.offsetWidth,'宽度')
                this.width=this.$refs.rolltable.$el.offsetWidth/10
            })
            const timer = setInterval(() => {
            // console.log("每10min刷新一次")
            this.getData()
            }, 600 * 1000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            })
        },
        destroyed(){
 
        },
        methods: {
            getData(){
                report({
                    current: 0,
                    formKey:"DuPGENwi",
                    queryId:"73",
                    queryParams: {
                        select1677570492939label:"蟠龙社区",
                    },
                    size: 100
                }).then(res=>{
                    // console.log(res.data.data.records,'随手报通知')
                    this.tableData=res.data.data.records
                })
            },
            getStatus(status){
                if(status=='2'){
                    return '已处理'
                }else if(status=='1'){
                    return '处理中'
                }else if(status=='0'){
                    return '未处理'
                }else{
                    ''
                }
            },
             startScroll() {
                this.tableScroll(false);
            },

            stopScroll() {
                this.tableScroll(true);
            },

            //滚动方法
            tableScroll(stop) {
                if (stop) {
                    clearInterval(rolltimer)
                    return
                }
                const table = this.$refs.rolltable
                const divData = table.bodyWrapper
                rolltimer = setInterval(() => {
                    divData.scrollTop += 1
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        if (table.tableData.length >=3) {
                            divData.scrollTop = 0
                        }
                    }
                }, 35)
            },
            handleTableRow(row, event, column) {
                this.$emit('clickTableRow',row)
                // console.log(row, event, column)
            },
            tableRowClassName({row, rowIndex,columnIndex}) {
                // console.log(row,'行')
                if (rowIndex%2==0) {
                    if (row.status == '0') {
                        return ['success-row','not-status']
                    }
                    return 'success-row';
                }
                if (rowIndex%2==1) {
                    if (row.status == '0') {
                        return ['warning-row','not-status']
                    }
                    return 'warning-row';
                }
                return '';
            }
        }
}
</script>
<style >
.el-table__row{
    cursor:pointer;
}
.el-table .warning-row {
background: #102e6d;
color: #ffffff;
}
.el-table .not-status .el-table__cell:last-child{
color: #fe6801;
}
.el-table .success-row {
background: #02082a ;
color: #ffffff;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:0;
}
 /* 去掉表格单元格边框 */
.el-table th{
    border:none;
  }
.el-table td,.el-table th.is-leaf {
  border:none;
}
 /* 表格最外边框 */
.el-table--border, .el-table--group{
  border: none;
}
 /* 头部边框 */
.el-table thead tr th.is-leaf{
  border-bottom: none;
  border-right: none;
}
/* 表格最外层边框-底部边框 */
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.el-table::before{
  width: 0;
}
.el-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
/* 表格有滚动时表格头边框 */
/* .el-table--border th.gutter:last-of-type {
    border: 1px solid #EBEEF5;  
    border-left: none;
} */
.el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #02082A !important;
}
.el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #6495ED !important;
}
.current-row > td {
   background: #6495ED !important;
}
::v-deep .is-scrolling-none {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
}
::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}
.el-table--scrollable-x .el-table__body-wrapper {
overflow-x: hidden;
}
</style>