<template>
    <div class="com-page">
        <left2a></left2a>
    </div>
</template>

<script>
    import left2a from '../src/components/left2a'
    export default{
        name:"left2Page1",
        components: { 
            left2a
        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>