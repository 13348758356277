<template>
    <div style="width: 100%;height: 100%;"> 
        <!-- <div style="margin-bottom: 20px;">
            <el-row class="box">
                <el-col  :span="1" >
                    <div class="circle"></div>
                </el-col>
                <el-col  :span="22">
                    <div class="text">居民上报社区居民居民上报社区居民居民上报社区居民居民上报社区居民居民上报社区居民</div>
                </el-col>
            </el-row>
        </div>
        <div style="margin-bottom: 20px;">
            <el-row class="box">
                <el-col  :span="1" >
                    <div class="circle"></div>
                </el-col>
                <el-col  :span="22">
                    <div class="text">居民上报社区居民居民上报社区居民居民上报社区居民居民上报社区居民居民上报社区居民</div>
                </el-col>
            </el-row>
        </div> -->
        <div v-for="item in list" :key="item" style="margin-bottom: 20px;">
            <el-row class="box">
                <el-col  :span="1" >
                    <div class="circle"></div>
                </el-col>
                <el-col  :span="22">
                    <div class="text">{{item}}</div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {getReportEvent} from '../../src/api/api'

    export default{
        name:"middleBottom2",
        data() {
            
            return{
                list:null
            };
        },
        mounted() {
            let params = {
                customerId: 5,
            };
            getReportEvent(params).then(res => {
                // console.log(res)
                if(res.data.code===201){
                    var data = res.data.data;
                    this.list = data
                }
            }).catch(err => {
                // console.log(err)
            })

        },
        destroyed(){
 
        },
        methods: {

        }

    }
</script>
<style>
    .box{
        height:30px;line-height:30px;vertical-align: middle;
    }
    .circle{
        width: 10px;height: 10px;border-radius: 50%;background-color: #f19610;margin-top: 10px;
    }
    .text{
        font-size: 18px;color: white;
    }
</style>