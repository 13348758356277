<template>
    <div class="box"
        v-loading="loading"  
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.4)"
    >
       <div class="box1">
            <div class="box-left">
                <div class="node-bg mb25 node1" @click="data=grid">{{gridData.name}}</div>
                <el-tree 
                    :data="treeChildren" 
                    :props="defaultProps" 
                    @node-click="handleNodeClick" 
                    class="tree-line" 
                    accordion
                    node-key="id"
                >
                    <span slot-scope="{ node }" class="slot-t-node">
                        <template>
                            <span class="node-bg node">{{ node.label }}</span>
                        </template>
                    </span>
                </el-tree>
            </div>
            <div class="box-right">
                <div class="title">
                    {{data.type=='unit'?(data.data.select1688521665631label+'-'+data.data.input1688527670011+'-'+data.data.input1688457496891):(data.name||gridData.name)+'基本介绍'}}
                </div>
                <div class="right-box" v-if="data.type!='unit'">
                    <el-image
                        :src="data.gridImage&&data.gridImage[0]&&data.gridImage[0].url?data.gridImage[0].url:require('../../src/assets/img/tou.png')"
                        fit="contain">
                    </el-image>
                    <div class="info-box">
                        <div class="info-title">
                            <span>{{data.type=="community"?'小区网格员：':(data.type=="building"?'楼栋长：':'网格长：')}}{{data.type=="building"?(data.buildName||'暂无'):(data.gridName||'暂无')}}</span>
                            <img src="../../src/assets/img/party.png" v-if="data.partyMember=='是'" class="info-img"/>
                        </div>
                        <div>电话：{{data.type=="building"?(data.buildPhone||'暂无'):(data.gridPhone||'暂无')}}</div>
                    </div>
                </div>
                <div class="right-box" v-if="data.type=='grid'">
                    <el-image
                        :src="data.gridChildrenImage&&data.gridChildrenImage[0]&&data.gridChildrenImage[0].url?data.gridChildrenImage[0].url:require('../../src/assets/img/tou.png')"
                        fit="contain">
                    </el-image>
                    <div class="info-box">
                        <div>{{'全科网格员：'}}{{data.gridChildrenName||'暂无'}}</div>
                        <div>电话：{{data.gridChildrenPhone||'暂无'}}</div>
                    </div>
                </div>
                <div class="params-box" v-if="data.type!='unit'">
                    <div class="flex">
                        <img :src="require(`../../src/assets/img/grid/${(data.type=='community'||data.type=='building')?'wg-tc':'wg-mj'}.png`)" class="img"/>
                        <div>
                            <div class="mj">{{(data.type=="community"||data.type=="building")?(data.ParkingSpace||0 ):(data.data&&data.data.input1687833397564||0)}}</div>
                            <div>{{(data.type=="community"||data.type=="building")?'车位(个)':'网格面积(公里²)'}}</div>
                        </div>
                    </div>
                    <div class="flex">
                        <img src="../../src/assets/img/grid/wg-hs.png" class="img"/>
                        <div>
                            <div class="hs">{{(data.type=="community"||data.type=="building")?(data.households||0 ):(data.data&&data.data.number1687833439085||0)}}</div>
                            <div>{{(data.type=="community"||data.type=="building")?'常住户数(户)':'服务户数(户)'}}</div>
                        </div>
                    </div>
                    <div class="flex">
                        <img src="../../src/assets/img/grid/wg-rk.png" class="img"/>
                        <div>
                            <div class="rk">{{(data.type=="community"||data.type=="building")?(data.population||0 ):(data.data&&data.data.number1687833479368||0)}}</div>
                            <div>{{(data.type=="community"||data.type=="building")?'常住人口(人)':'服务人口(人)'}}</div>
                        </div>
                    </div>
                </div>
                <div class="table-box" v-if="data.type=='unit'">
                    <div class="table-title">
                        <div class="unit">{{data.data.input1688457496891||''}}</div>
                        <div class="flex">
                            <div class="zizhu flexYCenter">
                                <div class="piece" style="background:#3f8ffb"></div>
                                <span>自住</span>
                            </div>
                            <div class="chuzhu flexYCenter">
                                <div class="piece" style="background:#DFA800"></div>
                                <span>出租</span>
                            </div>
                        </div>
                    </div>
                    <div class="table-content">
                         <el-table
                            :data="tableData"
                            height="100%"
                            style="width:100%;font-size:0.875em;"
                            :row-style="{background: 'transparent',color:'#fff'}"   
                            ref="rolltable"
                            :show-header="false"
                            :row-class-name="tableClassName"
                            @mouseenter.native="stopScroll"
                            @mouseleave.native="startScroll"
                        >
                        <!-- :header-cell-style="{background:'#102e6d',color:'#fff',width:'100%',height:'1.875em',fontSize:'0.875em'}" -->
                            <el-table-column
                                prop="storey"
                                label="楼层"
                                :width="width"
                            >
                                <template slot-scope="scope">
                                    <div class="first">
                                        {{scope.row.storey==0?'门面':(scope.row.storey?(scope.row.storey+'层'):'')}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-for="item in columnData"
                                :key="item"
                                label="房号"
                                :prop="item"
                                :width="width*2"
                            >
                                <template slot-scope="scope">
                                    <div class="door-box" v-if="scope.row[item]" :style="{'width':'100%'}">
                                       <div class="door-num">{{scope.row.storey?(scope.row.storey+(scope.row[item].number?('-'+scope.row[item].number):'')):(scope.row[item].number?scope.row[item].number:'')}}</div>
                                       <div class="type-box" v-if="scope.row.storey!=0">
                                            <span class="type" :style="{'background':scope.row[item].type=='出租'?'#DFA800':'#3f8ffb'}">{{scope.row[item].type||'自住'}}</span>
                                            <!-- <span v-else></span> -->
                                            <div class="hushu">
                                                <img src="../../src/assets/img/r.png" class="door-img"/>
                                                <span>{{scope.row[item].people||0}}</span>
                                            </div>
                                       </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div> 
                </div>
            </div> 
            <img  src="../../src/assets/img/close.png" class="close" @click="toClose"/>
       </div>
    </div>
</template>
<script>
import {getGridTree,getVillage}from '../../src/api/api'
let rolltimer
export default {
        name:"bulletBox3",
        props: {
			gridData:{
                type:Object,
                default:()=>{
                    return {}
                }
            }
		},
        data() {
            return {
                width:80,
                grid:{},
                data:{},
                treeChildren:[],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                tableData: [],
                columnData:[],
                type:null,
                loading:true,
            }
        },
        created(){
            this.getTree()
        },
        mounted(){
        },
        destroyed(){
 
        },
        methods: {
            getTree(){
                if(this.gridData.name==this.data.name) return
                this.loading=true
                getGridTree({
                    gridName:this.gridData.name
                }).then(res=>{
                    this.loading=false
                    // console.log(res,'res')
                    this.data=res.data.data[0]||[]
                    this.grid=res.data.data[0]||[]
                    this.treeChildren=this.data.children
                })
            },
            getRoomNumber(params){
                getVillage({
                    formKey: "qKuz1tME",
                    queryId: "99",
                    queryParams: params,
                    size: 999,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    // console.log(res,'房号')
                    let arr=
                    JSON.parse(JSON.stringify(res.data.data.rows)).map(item=>{
                        return {
                            storey:item.input1688462213829.includes('-')?item.input1688462213829.split('-')[0]:0,
                            number:item.input1688462213829.includes('-')?item.input1688462213829.split('-')[1]:item.input1688462213829.split('-')[0],
                            type:item.select1688701862454label,
                            people:item.input1688701925441,
                        }
                    })
                    // console.log(arr,'房号1')
                    this.tableData=this.sortArr(arr,'storey')
                     this.$nextTick(() => {
                        this.width=this.$refs.rolltable.$el.offsetWidth/((this.columnData.length)*2+1)                        
                        // console.log(this.$refs.rolltable.$el.offsetWidth,'宽度')
                        // console.log((this.columnData.length)*2+1,'宽度')
                    })
                    this.tableScroll(false)
                    // console.log(this.sortArr(arr,'storey'),'多少')
                    // console.log(this.columnData,'多少')   
                })
            },
            sortArr(arr, str) {
                var _arr = [],
                    _t = [],
                    // 临时的变量
                    _tmp;

                // 按照特定的参数将数组排序将具有相同值得排在一起
                arr = arr.sort(function (a, b) {
                    var s = a[str],
                    t = b[str];

                    return s < t ? -1 : 1;
                });

                if (arr.length) {
                    _tmp = arr[0][str];
                }
                // console.log( arr );
                // 将相同类别的对象添加到统一个数组
                for (var i in arr) {
                    if (arr[i][str] === _tmp) {
                    _t.push(arr[i]);
                    } else {
                    _tmp = arr[i][str];
                    _arr.push(_t);
                    _t = [arr[i]];
                    }
                }
                // 将最后的内容推出新数组
                _arr.push(_t); //获取到所有整合数据
                // console.log(_arr,'图三');//可以得到图三
                // 终极整合
                var integration = [];
                let number=[]
                _arr.forEach((item) => {
                    var eee = { storey: "", number: [] };
                    var obj = {};
                    item.forEach((self) => {
                        if(number.indexOf(self.number)==-1){
                            // number.push(self.number)
                            number.unshift(self.number)
                            // console.log(number,'number')
                        }
                        if (eee.storey != self.storey) {
                            eee.storey = self.storey;
                            obj['storey'] = self.storey;
                            obj['number'+self.number]={
                               number:self.number,
                               type:self.type,
                               people:self.people
                            }
                            // eee.number.push(self.number);
                             eee.number.unshift(self.number)
                        } else {
                            obj['number'+self.number]={
                               number:self.number,
                               type:self.type,
                               people:self.people
                            }
                            // eee.number.push(self.number);
                            eee.number.unshift(self.number);
                        }
                    });
                    // let arr=eee.number.sort(function (a, b) {   
                    //     return a-b;
                    // });
                    // if(arr.length>max){
                    //     max=arr.length
                    //     this.columnData=arr
                    // }
                    // integration.push(obj);
                    integration.unshift(obj);
                    this.columnData=number.sort(function (a, b) {   
                        return a-b;
                    }).map(res=>'number'+res)
                });
                integration.sort(function (a, b) {
                    var value1 = a['storey'];
                    var value2 = b['storey'];
                    return value1 - value2;
                });
                // this.columnData=this.columnData.map(res=>'number'+res)
                // console.log(integration,'图2');//图二
                // console.log(this.columnData)
                // console.log(this.polish(integration,this.columnData))
                // return integration;
                return this.polish(integration,this.columnData)
            },
            // 空位补齐
            polish(tableArr,columnArr){
                return tableArr.map(res=>{
                    columnArr.forEach(item=>{
                        if(!res[item]){
                            res[item]={
                                number:item.slice(6),
                                people:0,
                                type:null,
                            }
                        }
                    })
                    return res
                })
            },
            startScroll() {
                this.tableScroll(false);
            },

            stopScroll() {
                this.tableScroll(true);
            },

            //滚动方法
            tableScroll(stop) {
                if (stop) {
                    clearInterval(rolltimer)
                    return
                }
                const table = this.$refs.rolltable
                const divData = table.bodyWrapper
                rolltimer = setInterval(() => {
                    divData.scrollTop += 1
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        if (table.tableData.length >=7) {
                            divData.scrollTop = 0
                        }
                    }
                }, 80)
            },
            tableClassName({row, rowIndex,columnIndex}){
                // 给表第一列添加样式
                if(columnIndex==0){
                    return 'firstColumn'
                }
            },
            handleNodeClick(data) {
                this.type=data.type
                this.data=data
                if(data.type=="unit"){
                    this.loading=true
                    this.getRoomNumber({
                    select1688521665631label: data.data.select1688521665631label,//小区
                    input1688457496891: data.data.input1688457496891,//单元
                    input1688527670011: data.data.input1688527670011//栋
                })  
                }
                // console.log(data,'节点')
            },  
            toClose(){
                this.$emit('close')
            }
        }
}
</script>

<style scoped>
.info-title{
    display:flex;
    align-items: center;
    position:relative
}
.info-img{
    width:13.5px;
    height:13.5px;
    margin-left: 6.5px;
    position:relative;
    top:-6.5px;
    right:0;
}
.first{
    font-size: 15px;
    /* font-weight: bold; */
    color: #ffffff;
}
.door-box{
    width: 85px;
    background: #0163a7;
    padding:5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.door-num{
    font-size: 22px;
    /* font-weight: bold; */
    color: #ffffff;
    text-align: center;
    line-height:25px;
    margin: 5px 0;
}
.door-img{
    width:11px;
    height:12.5px;
    margin-right: 2.5px;
}
.type-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hushu{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    /* font-weight: bold; */
    color: #ffffff;
}
.type{
    display: inline-block;
    padding:0 5px;
    background: #3f8ffb;
    font-size: 15px;
    /* font-weight: bold; */
    color: #ffffff;
    /* margin-right: 9.5px; */
}
::v-deep .el-image{
    width:100px;
    height:104px;
}
::v-deep .cell{
    padding-left:0 !important;
    padding-right:5px !important;
}
.img{
    width:62px;
    height:62px;
    margin-right: 9.5px;
}
.mj{
    background-image:-webkit-linear-gradient(bottom,#0497fd 0%, #58ccfd 100%); 
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
}
.hs{
    background-image:-webkit-linear-gradient(bottom,#45e388 0%, #34ccb2 100%); 
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
}
.rk{
    background-image:-webkit-linear-gradient(bottom,#CD4267 0%, #FA8077 100%); 
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
}
.node-bg{
    background-image: url('../../src/assets/img/box/node-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}
.node{
    display: inline-block;
    padding:3px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}
.node1{
    display: inline-block;
    padding:7px 37.5px 7.5px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
}
.mb25{
    margin-bottom: 25px;
    
}
.box1{
    display: flex;
}
.flex{
    display: flex;
}
.flexYCenter{
    display: flex;
    align-items: center;
}
::v-deep .el-tree{
    background: transparent;
    color:#FFFFFF;
}
::v-deep .el-tree-node{
    position: relative;
        /* // 缩进量 */
    padding-left: 16px;
}
::v-deep .el-tree-node__children {
    /* // 缩进量 */
    padding-left: 16px; 
}
/* //这是竖线 */
::v-deep .el-tree-node :last-child:before {
    height: 40px;
}
::v-deep .el-tree > .el-tree-node:before {
border-left: none;
border-top: none;
}
::v-deep .el-tree > .el-tree-node:after {
border-top: none;
border-left: none;
}
::v-deep .el-tree-node__children .el-tree-node:before {
content: "";
left: -4px;
position: absolute;
right: auto;
border-width: 1px;
}
::v-deep .el-tree-node:after {
content: "";
left: -4px;
position: absolute;
right: auto;
border-width: 1px;
}
::v-deep .el-tree-node__expand-icon.is-leaf {
display: none;
}
::v-deep .el-tree-node:before {
border-left: 1px dashed #123677;
bottom: 0px;
height: 100%;
top: -26px;
width: 1px;
}
::v-deep .el-tree-node:after {
border-top: 1px dashed #123677;
height: 20px;
top: 20px;
width: 16px;
}
::v-deep .el-tree-node__content{
    margin-bottom: 15px;
    height:100% !important;
    background: transparent !important;
}
::v-deep .el-tree-node__content:hover{
    background: transparent;
}
::v-deep .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
::v-deep .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
/* //有子节点 且未展开 */
::v-deep .el-icon-caret-right:before {
    /* //树形控件点击图标更改为图片，需要自己设置 */
    content: url('../../src/assets/img/add.png');
    /* display: block;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background-size: 16px; */
}
/* //有子节点 且已展开 */
::v-deep .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    /* //树形控件点击图标更改为图片，需要自己设置 */
    content: url('../../src/assets/img/red.png');
    /* display: block;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background-size: 16px; */
}
/* //没有子节点 */
::v-deep .el-tree-node__expand-icon.is-leaf::before {
    background: transparent;
    content: '';
    /* display: block;
    width: 0px;
    height: 0px;
    font-size: 16px;
    background-size: 16px; */
}
/* //高亮字体颜色 */
::v-deep .el-tree-node.is-current > .el-tree-node__content {
    color: #54bee2 !important;
}




.box{
    width: 950px;
    height: 750px;
    padding:70px 40px;
    background-image: url('../../src/assets/img/box/box-bg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    display: flex;
    flex-direction: column;
    position: relative;
}
.topic{
    width:100%;
    padding-top: 5px;
    height:94px;
    line-height:94px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 31px;
}
.box-left{
    width:27%;
    height:630px;
    border-right:1px solid #2D3E5C;
    overflow: scroll;
}
.box-right{
    height:630px;
    padding:0 10px 0 15px;
    flex:1;
    overflow: scroll;
}
.box-right .title{
    padding:0 21.5px 8px;
    background-image: url('../../src/assets/img/box3-title.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 24px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    margin-bottom: 30px;
}
.box-right .info-box{
    background-image:linear-gradient(to right, #1A4062 , #041e3c);
    padding:23px 30px;
    flex:1;
    margin-left: 17px;
    font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    /* font-weight: bold; */
    color: #ffffff;
}
.right-box{
    margin-bottom: 41px;
    display: flex;
}
.box-right .params-box{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    /* font-weight: bold; */
    color: #ffffff;
}
.table-box{
    padding:10px 10px 0;
    background: #1a4062;
    color:#fff;
}
.table-title{
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 0.5px solid #0163a7;
    padding-bottom: 5px;
}
.table-content{
    width:100%;
    height:500px;
    padding:25.5px 0 0;
}
.table-box .unit{
    margin-left: 35.5px;
    font-size: 18px;
    /* font-weight: bold; */
    color: #ffffff;
}
.box-right .box-text{
    width:100%;
    /* height: 300px; */
    font-size: 18px;
    color: #ffffff;
    line-height: 36px;
    text-indent: 2em;
}
.zizhu{
    margin-right: 22px;
}
.chuzhu{
    margin-right: 10px;
}
.piece{
    display: inline-block;
    width: 21px;
    height: 17.5px;
    margin-right: 7.5px;
}
.box-imgs{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.box-img{
    margin-right: 10px;
    margin-bottom: 30px;
}
.box-img:nth-of-type(2n){
    margin-right:0;
}
.box-imgs img{
    width: 380px;
    height: 206px;
}
.close{
    width: 87px;
    height: 87px;
    position: absolute;
    top:-25px;
    right:-73px;
}
.close:hover{
    cursor:pointer;
}
::v-deep .el-table__empty-block{
    width:100% !important;
}
</style>