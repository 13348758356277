import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api"

// get请求
export function getStatistics(params){
    return http.get(`${resquest}/getStatistics`,params)
}
//获得人员对比信息
export function getPersonCompared(params){
    return http.get(`${resquest}/getPersonCompared`,params)
}
//获得辖区疫情对比
export function getEpidemicCompared(params){
    return http.get(`${resquest}/getEpidemicCompared`,params)
}
//获得疫情登记信息
export function getEpidemicRegistration(params){
    return http.get(`${resquest}/getEpidemicRegistration`,params)
}
//获得居民上报事件
export function getReportEvent(params){
    return http.get(`${resquest}/getReportEvent`,params)
}
//获得近几天居民上报趋势
export function getReportTrend(params){
    return http.get(`${resquest}/getReportTrend`,params)
}
//获得指定小区的详细信息
export function getBuildingInfo(params){
    return http.get(`${resquest}/getBuildingInfo`,params)
}
//获取小区地图数据
export function getMapData(params){
    return http.get(`${resquest}/getMapData`,params)
}
//右边第三个框数据
export function guanliData(params){
    return http.get(`${resquest}/guanliData`,params)
}
//左边第三个框数据-网格防控
export function getGrid(params){
    return http.get(`${resquest}/getGrid`,params)
}
//党建引领---左1
export function getPartyBuilding(params){
    return http.get(`${resquest}/getPartyBuilding`,params)
}
//党员年龄分布--左2
export function getPartyAge(params){
    return http.get(`${resquest}/getPartyAge`,params)
}
//人口信息对比-右1左图
export function getPersonCompared2(params){
    return http.get(`${resquest}/getPersonCompared2`,params)
}
//疫情登记信息----中间下面左一
export function getEpidemicRegistration2(params){
    return http.get(`${resquest}/getEpidemicRegistration2`,params)
}
//获得天气信息
// export function getWeather(params){
//     return http.get(`${resquest}/getWeather`,params)
// }

// post请求
export function postFormAPI(params){
    return http.post(`${resquest}/postForm.json`,params)
}
// put 请求
export function putSomeAPI(params){
    return http.put(`${resquest}/putSome.json`,params)
}
// delete 请求
export function deleteListAPI(params){
    return http.delete(`${resquest}/deleteList.json`,params)
}

//天气新接口
export function getWeather(params){
    return http.get(`/tduck-api/cloud/Weather/getWeather`,params)
}
//知社区-小区概况,知网格-机关干部-社区两委-网格员-地图网格区域-表单
export function getVillage(params){
    return http.post(`/tduck-api/user/form/public/query/data`,params)
}

//人口信息对比-环形图
export function getType(params){
    return http.get(`/tduck-api/cloud/status/getCount`,params)
}

//随手报通知
export function report(params){
    return http.get(`/tduck-api/cloud/status/clientPage`,params)
}

//知人口-人口概况
export function population(params){
    return http.get(`/tduck-api/cloud/status/demography`,params)
}

//获取网格架构图数据
export function getGridImage(params){
    return http.get(`/tduck-api/cloud/gridInfo/queryGridInfo`,params)
}

//获取随手报详情
export function getGridReply(params){
    return http.get(`/tduck-api/public/grid/getGridReply`,params)
}

//知网格-树形弹框
export function getGridTree(params){
    return http.get(`/tduck-api/cloud/specialPopulation/gridQuery`,params)
}

//知党建
export function getParty(params){
    return http.get(`/tduck-api/cloud/gridInfo/partyBuilding`,params)
}
//组织架构
export function getJiaGou(){
    return http.get(`/tduck-api/cloud/gridInfo/jiaGou`)
}

