<template>
    <div style="width:100%;height:100%;position: relative;">
        <div class="note" ref="myBox" :style="{backgroundImage:`url(${long?require('../../src/assets/img/box/bg2.png'):require('../../src/assets/img/box/frame.png')})`}">
            <div class="title head" :class="[long?'t':'']" ref="head">
                <div class="note1 title-box" :class="[long?'w':'']">
                  {{head}}
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
        name:"commen",
        props: {
			head:{
				type: String,
				default: "介绍"
			},
            //针对网格党建那横向背景比较长
            long:{
                type: Boolean,
                default:false
            }
		},
        data() {
            return {
                
            }
        },
        mounted() {
            // console.log(this.$refs.head,'距离上边')
            let height =  this.$refs.myBox.getBoundingClientRect().height
            this.$refs.head.style.top=height*12/514
            
        },
        destroyed(){
 
        },
        methods: {

        }
}
</script>

<style scoped>
.head{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:36px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    top: 2.33%;
}
.note{
    background-image: url("../../src/assets/img/box/frame.png");
    background-repeat:no-repeat;
    background-size:100% 100%;
    height:100%;
    width:100%;
    position: relative;
}
.note1{
    background-image: url("../../src/assets/img/box/headLeft1.png");
    background-repeat:no-repeat;
    background-size:100% 100%;       
 }
 .title-box{
    width: 239px;
    height: 36px;
    line-height:36px;
    text-align: center;
    color:#fff;
 }
 .t{
    top:2.6% !important;
 }
 .w{
    width:300px;
 }
</style>