<template>
  <div style="width: 99%; height: 98%;" class="bg1">
    <el-amap id="container"></el-amap>
    <div class="mapHead">
      <!-- <div class="firstLine">        
        <img src="../../src/assets/img/head.png"/>
        <div class="headText">{{name}}</div>
      </div> -->

      <!-- <div class="boxList">
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{buildings }}</div>
            <div class="content">楼栋总数</div>
          </div>
        </div>
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{householdTotal}}</div>
            <div class="content">总计户数</div>
          </div>
        </div>
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{permanentTotal}}</div>
            <div class="content">常驻人口</div>
          </div>
        </div>
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{partyTotal}}</div>
            <div class="content">党员总数</div>
          </div>
        </div>
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{medicalIsolation}}</div>
            <div class="content">医学隔离</div>
          </div>
        </div>
        <div class="numBox">
          <div class="module" :style ="module">
            <div class="number">{{homeObservation}}</div>
            <div class="content">居家观测</div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="mapFooter">
      <div>常驻人口数：84447人</div>
      <div>商家总数：1245家</div>
      <div>企业总数:187家</div>
    </div> -->
  </div>
</template>

<script>
  const AMap = window.AMap;

  import { getMapData,getVillage } from '../../src/api/api'
  import { getBuildingInfo } from '../../src/api/api'
  export default {
    name: "myMap",
    data() {
      return {
        buildings:1,
        homeObservation:962,
        householdTotal:1160,
        medicalIsolation:2,
        // name:"圣菲",
        name:"",
        partyTotal:2,
        permanentTotal:48,
        buildingId: null,
        infoWindow: {},
        module: {
          backgroundImage: "url(" + require("../../src/assets/img/mapModule.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          height: '100%',
          width: "100%"
        },
        map:null,
        marker:null,
        markers:[],
        markerArr:[],
        icon: new AMap.Icon({
          size: new AMap.Size(19, 32),    // 图标尺寸
          image: require("../../src/assets/img/marker.png"),  // Icon的图像
          imageSize: new AMap.Size(19, 32)   // 根据所设置的大小拉伸或压缩图片
        }),
      };
    },
    created() {

    },
    mounted() {
      this.initMap()
      // this.getAreaData();
      // const timer = setInterval(() => {
      //   console.log("每隔10分钟请求一次 地图")
      //   this.getAreaData();               
      // }, 600000);
      // // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
      // this.$once('hook:beforeDestroy', () => {
      //   clearInterval(timer);
      // })

    },
    beforeDestroy() { },
      methods: {
        //初始化地图
        initMap() {
          // console.log("地图初始化")
          this.map = new AMap.Map("container", {
            key: "d3e0dcbb715f673423492f2c93d6c859",
            rotateEnable: true,
            pitchEnable: true,
            zoom: 16.8,
            // zoom: 15,
            pitch: 42,
            rotation: -15,
            viewMode: '3D', //开启3D视图,默认为关闭
            zooms: [2, 20],
            // mapStyle: "amap://styles/darkblue",
            mapStyle: "amap://styles/108c7d5a9a4850dafa2ac83e9c58f1b4",
            // center: [104.003006, 30.567468],
            // center: [105.387039,30.890253],
            center: [105.39018563494871,30.89220573752962],//六网格         
          });
          // console.log("地图")
          // console.log(map)
          this.getAreaLine()
          this.getAreaData(this.map)
          // this.round(map)
        },
        //添加标记点
        addMarker(lngLatArr,name) {
          // 先清空标记点再增加
          this.markerArr=[]
          this.map.remove(this.markers);
          this.markers=[]
          if(this.markerArr.length==0){
            //直接往数组里加
              for (let i = 0; i < lngLatArr.length; i++){
                  if(JSON.parse(lngLatArr[i].input1687931763483)[1]>90){
                    lngLatArr[i].input1687931763483=JSON.stringify(JSON.parse(lngLatArr[i].input1687931763483).reverse())
                  }
                  this.marker = new AMap.Marker({
                    icon:this.icon,
                    position:new AMap.LngLat(JSON.parse(lngLatArr[i].input1687931763483)[0],JSON.parse(lngLatArr[i].input1687931763483)[1]),
                  });
                  // if(name=='小区'){
                    this.marker.id = lngLatArr[i].id;
                    this.marker.name=lngLatArr[i].input1687931744542;
                     // 设置鼠标划过点标记显示的文字提示
                    // this.marker.setTitle(lngLatArr[i].input1687931744542);

                    // 设置label标签
                    // label默认蓝框白底左上角显示，样式className为：amap-marker-label
                    this.marker.setLabel({
                        direction:'top',
                        offset: new AMap.Pixel(0, -3),  //设置文本标注偏移量
                        content:lngLatArr[i].input1687931744542||'', //设置文本标注内容
                    });
                    
                  // }
                  if(name=='小区'){
                    this.marker.on('click', this.gridPointClick); 
                  }
                  this.markers.push(this.marker)
                  this.markerArr.push(JSON.parse(lngLatArr[i].input1687931763483))  
              }
              this.map.add(this.markers);
              this.map.setCenter(this.getCenterPoint(lngLatArr.map(item => JSON.parse(item.input1687931763483))));
          }else{
            //如果数组里没有再往里加
            // console.log(this.markerArr)
            this.markerArr.map(item=>{
              for (let i = 0; i < lngLatArr.length; i++){
                if(lngLatArr[i].input1687931763483!=JSON.stringify(item)){
                  this.marker = new AMap.Marker({
                    icon:this.icon,
                    position:new AMap.LngLat(JSON.parse(lngLatArr[i].input1687931763483)[0],JSON.parse(lngLatArr[i].input1687931763483)[1]),
                  });
                  this.markers.push(this.marker)
                  this.markerArr.push(JSON.parse(lngLatArr[i].input1687931763483))
                }   
              }
            })
            this.map.add(this.markers);
            this.map.setCenter(this.getCenterPoint(lngLatArr.map(item => JSON.parse(item.input1687931763483))));
          }
        },
        // 清除标记点
        removeMarkers(arr){
          // console.log(this.markerArr,'标记数组')
          // console.log(this.markers,'标记点们')
          // console.log(arr,'arr')
          //清除所有标记点
          this.map.remove(this.markers);
          //清除指定标记点
          // arr.map(item=>{
          //   this.markerArr.map((data,index)=>{
          //     if(JSON.stringify(data)==JSON.stringify(item)){
          //       // console.log('来了吗')
          //       // console.log(index)
          //       this.map.remove(this.markers[index]);
          //       // this.markers[index].setMap(null)
          //       this.markerArr.splice(index,1)
          //       this.markers.splice(index,1)
          //     }
          //   }) 
          //   this.markerArr=this.markerArr.filter(data =>JSON.stringify(data)!=JSON.stringify(item))
          // })
        },
        //鼠标点击面积事件
        showInfoClick(e){
          this.$emit('clickArea',e)
        },
        gridTextClick(e){
          this.$emit('clickText',e)
        },
        //点击标记点
        gridPointClick(e){
          // console.log(e,'标记点')
          this.$emit('clickPoint',e)
        },
        // //鼠标移入面积事件
        // showInfoOver(e) {
        //   console.log("鼠标移入覆盖物")
        //   let params1 = {getCenterPoint
        //     buildingId: e.target.id,
        //   };
        //   getBuildingInfo(params1).then(res => {
        //     var list = res.data.data
        //     console.log("弹框信息")
        //     console.log(list)
        //     this.name = list.name
        //     this.buildings = list.buildings,
        //       this.homeObservation = list.homeObservation,
        //       this.householdTotal = list.householdTotal,
        //       this.medicalIsolation = list.medicalIsolation,
        //       this.partyTotal = list.partyTotal,
        //       this.permanentTotal = list.permanentTotal

        //   }).catch(err => {
        //     console.log(err)
        //   })

        // },
        // //鼠标移出面积事件
        // showInfoOut(e) {
        //   console.log("鼠标移出覆盖物")
        // },
        //根据接口传来的数据画面积
        draw(polygonArr, buildingId,name,map,params,type) {
          var polyGon = new AMap.Polygon({
            //这里设的是一维数组，多边形参数可设二维数组，如果设置二维数组，将出现的是一个中间镂空的多边形（也就是官方所说的带洞多边形）
            //二维数组 [外圈多边形点数组,内圈多边形点数组]
            path: polygonArr,
            cursor:'pointer',
            map: map,
            bubble: true,
            ...params
          });
          polyGon.id = buildingId;
          polyGon.name=name;
          // console.log(type,'类型')
          if(type=='网格'){
            polyGon.on('click', this.showInfoClick);
          }
          if(type=='小区'){
            polyGon.on('click', this.gridPointClick);
          }
          // polyGon.on('mouseover', this.showInfoOver);
          // polyGon.on('mouseout', this.showInfoOut);
        },
        /**
         * 获得图形的中心点(有点歪)
         */
        getCenterPoint(lnglatarr) {
          var total = lnglatarr.length;
          var X = 0,
            Y = 0,
            Z = 0;
          lnglatarr.map((item) => {
            var lng = (item[0] * Math.PI) / 180;
            var lat = (item[1] * Math.PI) / 180;
            var x, y, z;
            x = Math.cos(lat) * Math.cos(lng);
            y = Math.cos(lat) * Math.sin(lng);
            z = Math.sin(lat);
            X += x;
            Y += y;
            Z += z;
          });
          X = X / total;
          Y = Y / total;
          Z = Z / total;
          var Lng = Math.atan2(Y, X);
          var Hyp = Math.sqrt(X * X + Y * Y);
          var Lat = Math.atan2(Z, Hyp);
          return new AMap.LngLat((Lng * 180) / Math.PI, (Lat * 180) / Math.PI);
        },
        //请求网格区域面积数据
        getAreaData(map) {
          // let params = {
          //   customerId: 5,
          // };
          // getMapData(params).then(res => {
          //   console.log("地图数据")
          //   console.log(res)
          //   var list = res.data.data
          //   for (var i = 0; i < list.length; i++) {
          //     if (list[i].mapArea != null) {
          //       var mapArea = list[i].mapArea
          //       var id = list[i].id
          //       var color = list[i].colour
          //       this.draw(JSON.parse(mapArea), id, color,map)
          //     }
          //   }
          // })
          let params={
              current: 0,
              formKey: "ABg6U4ZK",
              queryId: "91",
              queryParams: {
                select1687833161884label: "蟠龙社区"
              },
              size: 100
          }
           getVillage(params).then(res => {
            // console.log("地图数据")
            // console.log(res)
            var list = res.data.data.rows
            for (let i = 0; i < list.length; i++) {
              if (list[i].input1687835518339 != null) {
                var mapArea = list[i].input1687835518339
                var id = list[i].id
                var color = list[i].input1687846031172
                var name=list[i].select1687833193489label
                // console.log(list[i],i)
                let mapParams={
                  fillColor: color,
                  // fillOpacity: 0.5,//填充颜色的透明度
                }
                this.draw(JSON.parse(mapArea), id,name,map,mapParams,'网格')
                //中心点标记
                var point = JSON.parse(list[i].input1687848952182)
                var text = new AMap.Text({
                      text:list[i].select1687833193489label,
                      anchor:'center', // 设置文本标记锚点
                      cursor:'pointer',
                      style:{
                          'background-color': 'transparent',
                          'border-width': 0,
                          'text-align': 'center',
                          'font-size': '1.5em',
                          'color': 'white',
                          'font-weight':'bold'
                      },
                      position:point
                  });
                  // console.log(text,'文本标记')
                  text.id = id;
                  text.name=name;
                  text.on('click', this.gridTextClick);
                  text.setMap(map);    
              }
            }
          })
          this.getArea()
        },
        //获取边界
        getAreaLine(){
          getVillage({
            formKey: "rt3fflFN",
            queryId: "107",
            queryParams: {
              select1688976091023label: "蟠龙社区"
            },
            size: 100,
            current: 0
          }).then(res=>{
            // console.log(res,'边界')
            let list=res.data.data.rows
             for (let i = 0; i < list.length; i++) {
              if (list[i].input1688976102585 != null) {
                var mapArea = list[i].input1688976102585
                var id = list[i].id
                var name='边界'+i
                // console.log(list[i],i)
                let params={
                  fillOpacity: 0,//填充颜色的透明度
                  strokeOpacity: 1,
                  strokeColor: 'yellow',
                  strokeWeight: 2,
                  strokeStyle: 'dashed',
                  strokeDasharray: [5, 5],
                }
                this.draw(JSON.parse(mapArea), id,name, this.map,params)
              }
            }
          })
        },
        // 获取小区区域面积
        getArea(){
          getVillage({
              formKey: "pUzJUN87",
              queryId: "106",
              queryParams: {
              select1688976019312label: "蟠龙社区"
            },
            size: 100,
            current: 0
            }).then(res=>{
            // console.log(res,'小区')
            let list=res.data.data.rows
             for (let i = 0; i < list.length; i++) {
              if (list[i].input1688976031979 != null) {
                var mapArea = list[i].input1688976031979
                var id = list[i].id
                var name=list[i].input1689041991829
                // console.log(list[i],i)
                let params={
                  fillColor: '#e370ea',
                  fillOpacity:0.3,//填充颜色的透明度
                  strokeOpacity: 1,
                  strokeColor: '#9dabef',
                  strokeWeight: 1,
                  strokeDasharray: [5, 5],
                }
                // this.draw(JSON.parse(mapArea), id,name, this.map,params,'小区')
                //  //中心点标记
                // var point = JSON.parse(list[i].input1688982348304)
                // var text = new AMap.Text({
                //       text:list[i].input1689041991829,
                //       anchor:'center', // 设置文本标记锚点
                //       cursor:'pointer',
                //       style:{
                //           'background-color': 'transparent',
                //           'border-width': 0,
                //           'text-align': 'center',
                //           'font-size': '1em',
                //           'color': 'yellow',
                //           'font-weight':'bold'
                //       },
                //       position:point
                //   });
                //   // console.log(text,'文本标记')
                //   text.id = id;
                //   text.name=name;
                //   text.on('click', this.gridPointClick);
                //   text.setMap(this.map);       
              }
            }
          })
        },
        // //地图区域面积轮巡
        // round(map){
        //   let params = {
        //     customerId: 5,
        //   };
        //   getMapData(params).then(res => {
        //     console.log("地图数据-------")
        //     console.log(res)
        //     var list = res.data.data
        //     var i = 0;
        //       const timer1 = setInterval(() => {
        //         console.log("每隔10分钟轮巡一个区域")
        //         i++;
        //         console.log(i)
        //         console.log(list[i])
        //         // console.log(list[i].mapArea)
        //         // if (list[i].mapArea != null) {
        //           var polygon = new AMap.Polygon({
        //             map: map,
        //             strokeWeight: 1,
        //             strokeColor: 'red',
        //             fillColor: 'red',
        //             fillOpacity: 0.2,
        //             path: list[i].mapArea
        //           });
        //         // }
        //         if(i>5){
        //           clearInterval(timer1);
        //         }

        //       }, 600);

        //   }).catch(err => {
        //     console.log(err)
        //   })
        // },
      }
  };
</script>

<style>
  /* 隐藏高德地图水印 */
  .amap-logo {
    display: none !important;
    visibility: hidden !important;
  }

  .amap-copyright {
    display: none !important;
    visibility: hidden !important;
  }

  .infoWindow {
    width: 370px;
    height: 155px;
    background: url('../../src/assets/img/infoWindow.png') no-repeat 100% 100%;
    background-size: 100% 100%;
  }

  .infoWindow .line1 {
    width: 100%;
    height: 22%;
    font-size: 16px;
    color: #7195b6;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .infoWindow .line2 {
    width: 100%;
    height: 78%;
    display: flex;
    display: inline-flex;
    text-align: center;

  }

  .infoWindow .line2 .col {
    width: 50%;
    height: 100%;
    font-size: 16px;
    color: #7195b6;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .mapHead{
    width: 100%;min-height: 10px;position: absolute;top: 0;text-align: center;
  }
  .firstLine{
    height:50px;width: 50%;margin-left: 25%;
  }
  .headText{
    /* width: 100%;min-height: 20px;
    position:absolute;top: 0;text-align: center;font-size: 18px;color: white;margin-top: 15px; */
    width: 60%;height:25px;margin-left: 20%;margin-top: -35px;color: white;font-size: 18px;overflow: hidden;
  }
  .boxList{
    width: 86%;margin-left:7%;height: 100px;display: flex;justify-content: space-between;
  }
  .numBox{
    width: 100px;height: 100px;
  }
  .numBox .number{
    color: white;
    font-size: 26px;
    position: relative;
    top:20px;
  }
  .numBox .content{
    color: #88b1d0;
    font-size: 16px;
    position: relative;
    top:30px;
  }
  .mapFooter{
    width: 68%;height: 36px;
    margin-left: 16%;
    position: relative;top: -30px;
    display: flex;justify-content: space-between;
    font-size: 18px;color: #88b1d0;
  }
  .amap-marker-label{
    border: 0;
    /* background-color: transparent; */
    /* color:yellow; */
    cursor: pointer;
  }
</style>

<!-- <div class="infoWindow">
  <div class="line1">圣菲town城</div>
  <div class="line2">
    <div class="col">
      <div>楼栋总数:22栋</div>
      <div>总计户数:8752户</div>
      <div>常驻人口:23452人</div>
    </div>
    <div class="col">
      <div>党员总数:158人</div>
      <div>医学隔离:5人</div>
      <div>居家观测:13人</div>
    </div>
  </div>
</div> -->