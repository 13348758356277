<template>
    <div class="com-container" >
        <div class="com-chart" ref="right1_ref"></div>
    </div>
  </template>
  
  <script>
    import { getPersonCompared2 } from '../../src/api/api'
    export default{
        name:"left1",
        data() {
            return {
                chartInstance:null,
            };
        },
        mounted() {
            this.getData()
            const timer = setInterval(() => {
                console.log("每10min刷新一次 --右1")
                this.getData()
            }, 600 * 1000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            })
        },
        destroyed(){
   
        },
        methods: {
            //初始化echartInstance对象
            initChart(data){
                this.chartInstance = this.$echarts.init(this.$refs.right1_ref)
                //对图表初始化配置的控制
                const initOption = {
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: '50%',
                            labelLine: {
								normal: {
									lineStyle: {
										color: 'rgba(255, 255, 255, 0.3)'
									},
									smooth: 0.2,
									length:1,
				
								}
							},
                            label: {
								normal: {
									show: true,
									// formatter: "{d}% ",
									textStyle: {
										fontSize: this.standSize / 150,
										color: "#8296b2"
									},
								},
							},

                            data: [
                                { value: data[0], name: '男' },
                                { value: data[1], name: '女' }
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 1,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                }
                this.chartInstance.setOption(initOption)
            },
            getData() {
                let params = {
                    customerId: 5,
                    ageDivide: "18,40,60"
                };
                getPersonCompared2(params).then(res => {
                    console.log("人口信息对比")
                    console.log(res)
                    var data = res.data.data
                    console.log(data.sex0)
                    console.log(data.sex1)
                    var sex = []
                    var sex0 = data.sex0
                    var sex1 = data.sex1
                    sex.push(sex0)
                    sex.push(sex1)
                    console.log(sex)
                    this.initChart(sex)
                }).catch(err => {
                    console.log(err)
                })
            }
        },
  
    }
  </script>