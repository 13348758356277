<template>
    <div class="com-container" >
        <div class="com-chart" ref="right1_ref"></div>
    </div>
</template>

<script>
    import { getPartyAge } from '../../src/api/api'
    export default{
        name:"left1",
        data() {
            return {
                chartInstance:null,
            };
        },
        mounted() {
            this.getData()
            const timer = setInterval(() => {
                // console.log("每10min刷新一次")
                this.getData()
            }, 600 * 1000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            })

        },
        destroyed(){
        },
        methods: {
            //初始化echartInstance对象
            initChart(data){
                this.chartInstance = this.$echarts.init(this.$refs.right1_ref)
                //对图表初始化配置的控制

                const initOption = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        top:'5%',
                        left: 0,
                        right: '4%',
                        bottom: 0,
                        height:'90%',
                        containLabel: true,

                    },
                    xAxis: {
                        type: 'category',
                        data: ['18~30岁', '30~45岁', '45~55岁', '55~70岁', '70岁以上'],
                        // splitLine: { 
                        //     show: true,
                        //     lineStyle: {
                        //         color: ['#003a87'],
                        //         width: 1,
                        //         type: 'solid'
                        //     }
                        // },
                        axisLine:{
                            show:false,
                            lineStyle:{
                                color:'#003a87'
                            }
                        },
                        axisTick: {
                            show: false   //刻度线
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#8ab3d1'
                            }
                        }

                    },
                    yAxis: [
                        {
                            type: 'value',
                            // data: [200, 400, 600, 800, 1000],
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ['#003a87'],
                                    width: 1,
                                    type: 'solid'
                                }
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#003a87'
                                }
                            },
                            axisLabel: {
                                show: true,
                                textStyle: {
                                    color: '#8ab3d1'
                                }
                            },
                        },
                        // 这里是利用双轴线展示成给图表添加右边框的效果
                        {
                            type: 'value',
                            interval: 50,
                            axisTick: { show: false },
                            axisLine: {
                                show:true,
                                lineStyle: {
                                    color: '#003a87'
                                }
                            },
                            axisLabel: {
                                textStyle: {
                                    // 在这里设置右边Y轴文字颜色
                                    color: 'transparent'
                                }
                            }
                        }

                    ],

                    series: [
                        {
                            type: 'bar',
                            barWidth: '40%',//设置柱状图的柱状的宽度
                            // stack: 'Total',
                            // data: [120, 132, 101, 134, 90],
                            data: data,
                            // symbol:'none',
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        var colorList = ['#ee6f4e', '#ebbf46', '#38d661', '#1f80e5', '#8a00e1'];
                                        return colorList[params.dataIndex];
                                    },
                                }
                            }
                            
                        }
                    ]
                }
                this.chartInstance.setOption(initOption)
            },
            getData(){
                let params = {
                customerId: 5,
            };
            getPartyAge(params).then(res => {
                // console.log("党员年龄分布")
                // (res.data.data)
                var list = res.data.data
                var data = [list.age1,list.age2,list.age3,list.age4,list.age5]
                // console.log(data)
                this.initChart(data)
            }).catch(err => {
                // console.log(err)
            })
            }
        },

    }
</script>