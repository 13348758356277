<template>
    <div class="com-container" >
        <div class="com-chart" ref="right2_ref"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
    export default{
        name:"right2",
        data() {
            return {
                chartInstance:null,
                // allData:null,    //服务器返回的数据
            };
        },
        mounted() {
            this.initChart()
            this.getData()
            window.addEventListener('resize',this.screenAdapter)
            //页面加载完成后主动进行屏幕的适配
            // this.screenAdapter()
        },
        destroyed(){
            //在组件销毁的时候需要将监听器取消
            window.removeEventListener('resize',this.screenAdapter)
        },
        methods: {
            //初始化echartInstance对象
            initChart(){
                this.chartInstance = this.$echarts.init(this.$refs.right2_ref)
                //对图表初始化配置的控制
                const initOption = {
                    // title: {
                    //     text: 'Stacked Line'
                    // },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor:'#266de9',
                        borderColor: "#266de9",
                        textStyle: {
                            color: "rgba(255, 255, 255, 1)"
                        }

                        // trigger: 'item',
                        // backgroundColor: "transparent",
                        // borderWidth: 1,
                        // padding: 10,
                        // formatter: function(params) {
                        //     console.log(str);
                        //     // var str =params['percent']+'%'+'<br/>'+params.data.value+'笔'
                        //     var str = `<div style = "background:url( ${require("../../src/assets/img/icon1.png")}) no-repeat center center ;width:20px;height:40px; ">
                        //     ${
                        //     params[0].seriesName
                        //     }<br/>
                            
                        //     ${
                        //     params[1].seriesName
                        //     }
                        //      </div>`;
                        //     // parms.marker
                        //     console.log(str);
                        //     return str;
                        // },
                    },
                    legend: {
                        // top: 'bottom',
                        // data: ['商家一', '商家二'],
                        orient: "vertical",
                        right: "0",
                        textStyle: {
                          color: '#fft'
                        }
                    },
                    grid: {
                        with:'auto',
                        left: '0',
                        right: '20%',
                        bottom: '3%',
                        top: '10%',
                        containLabel: true
                    },
                    // toolbox: {
                    //     feature: {
                    //         saveAsImage: {}
                    //     }
                    // },

                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['2017', '2018', '2019', '2020', '2021', '2022'],
                        axisLabel: {
                            color: "#569ccb"
                        },
                        axisPointer: {
                            type:'line',
                            // value: '2020-10-7',
                            snap: true,
                            lineStyle: {
                                color: '#193463',
                                width: 2
                            },
                            handle: {
                                show: true,
                                color: '#8296b2',
                                size:0
                            }
                        },    
                        splitLine: {
                            show: false//x分格线
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#163554"
                            }
                        },
                        
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            color: "#569ccb"
                        },
                        splitLine: {
                            show: true,//x分格线
                            lineStyle:{
                                color:'#0e274c'
                            }
                         }
                    },
                    series: [
                        {
                            name: '商家1',
                            type: 'line',
                            smooth: false,
                            symbol: 'circle',
                            symbolSize: 5,
                            sampling: 'average',
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(9, 206, 245,0.2)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0,0,0,0.3)'
                                }
                                ])
                            },
                            data: [
                                [2020, 20],
                                [2021, 30],
                                [2022, 60],
                                [2023, 70],
                                [2024, 140],
                                [2025, 290]
                            ],
                            lineStyle: {
                                color: 'rgba(9, 206, 245, 1)'
                            },
                            itemStyle: {
                                color: 'rgba(9, 206, 245, 10)'
                            },
                        },
                        {
                            name: '商家2',
                            type: 'line',
                            smooth: false,
                            stack: 'a',
                            symbol: 'circle',
                            symbolSize: 5,
                            sampling: 'average',
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(49, 136, 253,0.2)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0,0,0,0.3)'
                                }
                                ])
                            },
                            data: [
                                [2020, 20],
                                [2021, 30],
                                [2022, 60],
                                [2023, 70],
                                [2024, 140],
                                [2025, 290]
                            ],
                            lineStyle: {
                                color: 'rgba(49, 136, 253, 1)'
                            },
                            itemStyle: {
                                color: 'rgba(49, 136, 253, 10)'
                            }
                            }
                    ]
                }
                this.chartInstance.setOption(initOption)
            },
            //获取服务器数据
            async getData(){
                // const {data:ret} = await this.$http.get('yourUrl')
                // console.log(ret)
                // this.allData = ret
                this.updateChart()
            },
            //更新图表
            updateChart(){
                // const left1Names = this.allData.map((item) => {
                //     return item.name
                // })
                // const left1Values = this.allData.map((item) => {
                //     return item.value
                // })
                const dataOption = {
                    series: [
                        {
                            data: [3000, 4100, 5800, 6050, 6500, 6400]
                        },
                        {
                            data: [1700, 2900, 4050, 4400, 6100, 8000]
                        },
                    ]
                }
                this.chartInstance.setOption(dataOption)
            },
            //当浏览器的大小发生变化的时候会调用的方法，来完成屏幕的适配
            screenAdapter(){
                // console.log(this.$refs.left1_ref.offsetWidth)
                //  console.log(this.$refs,'宽度')
                // const titleFontSize = this.$refs.left1_ref.offsetWidth / 100 *3.6
                const titleFontSize =  this.$refs.right2_ref.offsetWidth / 100 *3.6
                // console.log(titleFontSize)
                //和分辨率相关的配置项
                const adapterOption = {
                    title:{
                        textStyle:{
                            fontSize:titleFontSize,
                        }
                    }
                }
                this.chartInstance.setOption(adapterOption)
                //手动调用图表对象的resize才能产生效果
                this.chartInstance.resize()
            }
        },

    }
</script>