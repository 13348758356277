<template>
  <div 
    style="display:flex;color:#fff;width:100%;height:100%"
    v-loading="loading"  
    element-loading-text="数据加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.3)"
    >
    <div style="width:48%;height:90%;margin-right:4%">
        <div class="partyMember-title">双报到党员 {{tableDataTotal||0}}人</div>
        <el-table
            :data="tableData"
            height="100%"
            style="width:100%;font-size:1.125em"
            :row-style="{height:'1.875em'}"   
            :header-cell-style="{background:'#1e3b62',color:'#fff',width:'100%',height:'0.375em',textAlign: 'center'}"
            ref="rolltable"
            :row-class-name="tableRowClassName"
            @row-click="handleTableRow"
            @mouseenter.native="stopScroll"
            @mouseleave.native="startScroll"
        >
        
            <el-table-column
                prop="input1688545629702"
                label="姓名"
                :width="width*2"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545629702||''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="input1688545636361"
                label="性别"
                :width="width*1.5"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545636361||''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="input1688545651320"
                label="年龄"
                :width="width*1.5"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545651320||''}}
                    </div>
                </template>
            </el-table-column>
             <el-table-column
                prop="input1689214355974"
                label="入党时间"
                :width="width*2.8"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1689214355974||''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="input1689214282964"
                label="服务小区"
                :width="width*2.3"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1689214282964||''}}
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div style="width:48%;height:90%;">
        <div class="partyMember-title">直管党员 {{domicileTotal||0}}人</div>
        <el-table
            :data="domicile"
            height="100%"
            style="width:100%;font-size:1.125em"
            :row-style="{height:'1.875em'}"  
            :header-cell-style="{background:'#1e3b62',color:'#fff',width:'100%',height:'2.375em',textAlign: 'center'}"
            ref="rolltable1"
            :row-class-name="tableRowClassName"
            @row-click="handleTableRow1"
            @mouseenter.native="stopScroll1"
            @mouseleave.native="startScroll1"
        >
            <el-table-column
                prop="input1688545629702"
                label="姓名"
                :width="width*2"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545629702||''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="input1688545636361"
                label="性别"
                :width="width*1.5"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545636361||''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="input1688545651320"
                label="年龄"
                :width="width*1.5"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1688545651320||''}}
                    </div>
                </template>
            </el-table-column>
             <el-table-column
                prop="input1689214355974"
                label="入党时间"
                :width="width*2.8"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1689214355974||''}}
                    </div>
                </template>
            </el-table-column>
             <el-table-column
                prop="input1689064015706"
                label="居住小区"
                :width="width*2.3"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.input1689064015706||''}}
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
import {getVillage} from '../../src/api/api'
import {secrecyMobile}from '../../src/utils/util'
let rolltimer
let rolltimer1
export default {
        name:"partyMember",
        data() {
            return {
                tableData: [],
                domicile:[],
                loading:true,
                width:40,
                width1:40,
                tableDataTotal:0,
                domicileTotal:0,
            }
        },
        created(){
            this.getData()
        },
        mounted(){
          this.tableScroll(false)
          this.tableScroll1(false)
           this.$nextTick(() => {
                // console.log(this.$refs.rolltable.$el.offsetWidth,'宽度')
                this.width=this.$refs.rolltable.$el.offsetWidth/10
                // console.log(this.width,'宽度')
            })
            // this.$nextTick(() => {
            //     console.log(this.$refs.rolltable1.$el.offsetWidth,'宽度')
            //     this.width1=this.$refs.rolltable1.$el.offsetWidth/8.5
            //     console.log(this.width1,'宽度')
            // })
        },
        destroyed(){
 
        },
        methods: {
            handleTableRow(row, event, column) {
                this.$emit('clickTableRow',row)
                // console.log(row, event, column)
            },
             handleTableRow1(row, event, column) {
                this.$emit('clickTableRow1',row)
                // console.log(row, event, column)
            },
            mobile(phone){
                return secrecyMobile(phone)
            },
            getData(){
                getVillage({
                    formKey: "BJjNriVi",
                    queryId: "103",
                    queryParams: {
                        select1688545587583label: "直管党员"
                    },
                    size: 500,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    // console.log(res,'户籍党员')
                    this.domicile=res.data.data.rows||[]
                    this.domicileTotal=res.data.data.total
                })
                getVillage({
                    formKey: "BJjNriVi",
                    queryId: "103",
                    queryParams: {
                        select1688545587583label: "双报到党员"
                    },
                    size: 1000,
                    current: 0
                }).then(res=>{
                    this.loading=false
                    // console.log(res,'双报到党员')
                    this.tableData=res.data.data.rows||[]
                    this.tableDataTotal=res.data.data.total
                })
            },
             startScroll() {
                this.tableScroll(false);
            },
            stopScroll() {
                this.tableScroll(true);
            },
             startScroll1() {
                this.tableScroll1(false);
            },
            stopScroll1() {
                this.tableScroll1(true);
            },
            //滚动方法
            tableScroll(stop) {
                if (stop) {
                    clearInterval(rolltimer)
                    return
                }
                const table = this.$refs.rolltable
                const divData = table.bodyWrapper
                rolltimer = setInterval(() => {
                    divData.scrollTop += 1
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        if (table.tableData.length >=3) {
                            divData.scrollTop = 0
                        }
                    }
                }, 35)
            },
             tableScroll1(stop) {
                if (stop) {
                    clearInterval(rolltimer1)
                    return
                }
                const table1 = this.$refs.rolltable1
                const divData1 = table1.bodyWrapper
                rolltimer1 = setInterval(() => {
                    divData1.scrollTop += 1
                    if (divData1.clientHeight + divData1.scrollTop + 1 > divData1.scrollHeight) {
                        if (table1.tableData.length >=3) {
                            divData1.scrollTop = 0
                        }
                    }
                }, 35)
            },
             tableRowClassName({row, rowIndex,columnIndex}) {
                // console.log(row,'行')
                if (rowIndex%2==0) {
                    return 'singular';
                }
                if (rowIndex%2==1) {
                    return 'dual';
                }
                return '';
            }
        }
}
</script>
<style>
.partyMember-title{
    font-size: 18px;
    color: #ffffff;
    padding-left:27px;
    padding-bottom: 20px;
}
.el-table__row{
    cursor:pointer;
}
.el-table .dual {
background: #2A4D7C !important;
color: #ffffff;
text-align: center;
}
.el-table .not-status .el-table__cell:last-child{
color: #fe6801;
}
.el-table .singular {
background: #365887 !important;
color: #ffffff;
}
.el-table .singular .cell{
    text-align: center;
}
.el-table .dual .cell{
    text-align: center;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:0;
}
 /* 去掉表格单元格边框 */
.el-table th{
    border:none;
  }
.el-table td,.el-table th.is-leaf {
  border:none;
}
 /* 表格最外边框 */
.el-table--border, .el-table--group{
  border: none;
}
 /* 头部边框 */
.el-table thead tr th.is-leaf{
  border-bottom: none;
  border-right: none;
}
/* 表格最外层边框-底部边框 */
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.el-table::before{
  width: 0;
}
.el-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
/* 表格有滚动时表格头边框 */
/* .el-table--border th.gutter:last-of-type {
    border: 1px solid #EBEEF5;  
    border-left: none;
} */
.el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #02082A !important;
}
.el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #6495ED !important;
}
.current-row > td {
   background: #6495ED !important;
}
::v-deep .is-scrolling-none {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
}
::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}
.el-table--scrollable-x .el-table__body-wrapper {
overflow-x: hidden;
}
::v-deep .cell{
    padding-left:0 !important;
    padding-right:0 !important;
}
</style>