<template>
  <div class="com-container" >
      <div class="com-chart" ref="right1_ref"></div>
  </div>
</template>

<script>
  import { population,getType } from '../../src/api/api'
  import * as echarts from "echarts";
  export default{
      name:"leftCircular",
      data() {
          return {
              chartInstance:null,
          };
      },
      mounted() {
        this.getData()
        // const timer = setInterval(() => {
        //   console.log("每10min刷新一次")
        //   this.getData()
        // }, 600 * 1000);
        // // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        // this.$once('hook:beforeDestroy', () => {
        //   clearInterval(timer);
        // })
      },
      destroyed(){
 
      },
      methods: {
          //初始化echartInstance对象
          initChart(data){
              this.chartInstance = this.$echarts.init(this.$refs.right1_ref)
              let child=data.child||0
              let youth=data.youth||0
              let middleAge=data.middleAge||0
              let sum=child+youth+middleAge
              //对图表初始化配置的控制
              const initOption = {
                tooltip: {
                  // trigger: 'item'
                },
                color:["#3f8ffb","#ebe152","#f2ac74"],
                // color: [
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#46AEF7" },
                //         { offset: 1, color: "#1DD5E6" }
                //     ]),
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#12D6DF" },
                //         { offset: 1, color: "#F70FFF" }
                //     ]),
                //     new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //         { offset: 0, color: "#FDC2D8" },
                //         { offset: 0.5, color: "#FCA65E" },
                //         { offset: 1, color: "#FF7983" },
                //     ]),
                //     //  new echarts.graphic.LinearGradient(1, 1, 0, 1, [
                //     //     { offset: 0, color: "#ffc2a1" },
                //     //     { offset: 1, color: "#ffb1bd" },
                //     // ]),
                // ],
                legend: {
                  orient: "vertical",
                  x: 'left', //可设定图例在左、右、居中
                  y:25,
                  left: 10,
                  textStyle: {
                    color: '#FFFFFF'
                  }
                },
                 title :{
                  text :'年龄结构',
                  textStyle:{
                    color:"#fff",
                    fontSize:16,
                    align:"left",
                  },
                  subtext :'',
                  x:'10px',
                  y:0,
                },
                grid: {
                  left: 0,
                  right: 0,
                  bottom: '0',
                  // containLabel: true,
                  width: '60%',
                  height: "90%",
                },
                series: [{
                    type: 'pie',
                    // roundCap:true,
                    // itemStyle: {
                    //     borderRadius: 60, // 设置每一段子项目的圆角
                    //     borderWidth: 10, // 子项目间距
                    // },
                    radius:'50%',
                    center:['60%',"50%"],
                    // radius: ['45%', '60%'],
                    // center:['70%',"50%"],
                    label:{
                      show:false
                    },
                    labelLine: {
                      show: false
                    },
                    data: [
                      {name:`0-18岁${sum!=0?(child*100/sum).toFixed(1):0}%`,value:child},
                      {name:`19-59岁${sum!=0?(youth*100/sum).toFixed(1):0}%`,value:youth},
                      {name:`60及以上${sum!=0?(middleAge*100/sum).toFixed(1):0}%`,value:data.middleAge},
                    ]
                  }]
              }
              this.chartInstance.setOption(initOption)
          },
          getData() {
            population({
                formKey:"Fj9XoSvY",
            }).then(res=>{
                // console.log(res,'人口')
                this.initChart(res.data.data)
            })
          }
      },

  }
</script>