<template>
  <div 
    class="organize-page"
    v-loading="loading"  
    element-loading-text="数据加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.3)"
    >
    <SeeksRelationGraph ref="seeksRelationGraph" :options="graphOptions" :on-node-click="onNodeClick" v-show="showJg"></SeeksRelationGraph>
     <div class="top" v-if="showDw">
        <div class="title">
            社区党委
            <div class="back" @click="toBack('showDw','showJg')">
                <i class="el-icon-arrow-left"></i>
                返回
            </div>
        </div>
        <div class="organize-bigBox">
            <div style="display:flex;flex-wrap:wrap;">
                <div class="organize-box" v-for="data in list1" :key="data.id">
                    <el-image
                        class="head-img"
                        :src="data.image_upload1688546377081&&data.image_upload1688546377081[0]&&data.image_upload1688546377081[0].url?data.image_upload1688546377081[0].url:require('../../src/assets/img/tou.png')"
                        fit="contain"
                        :preview-src-list="data.image_upload1688546377081.map(res=>res.url)"
                    >
                    </el-image>
                    <div class="info">
                        <div>
                            <div>姓名：{{data.input1688546345189||'未知'}}</div>
                            <div class="geyan">职务：{{data.input1688546357336||'暂无'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display:flex;flex-wrap:wrap;">
                <div class="organize-box2" v-for="data in list2" :key="data.id">
                    <el-image
                        class="head-img"
                        :src="data.image_upload1688546377081&&data.image_upload1688546377081[0]&&data.image_upload1688546377081[0].url?data.image_upload1688546377081[0].url:require('../../src/assets/img/tou.png')"
                        fit="contain"
                        :preview-src-list="data.image_upload1688546377081.map(res=>res.url)"
                    >
                    </el-image>
                    <div class="info">
                        <div>
                            <div>姓名：{{data.input1688546345189?(data.input1688546345189+'（兼职委员）'):'未知'}}</div>
                            <div class="geyan">职务：{{data.input1688546357336||'暂无'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showZz" class="partyZong">
        <div class="revert" @click="toBack('showZz','showJg')">
            <i class="el-icon-arrow-left"></i>
            返回
        </div>
        <div style="display:flex;height:90%">
            <div style="width:48%;height:95%;margin-right:4%">
                <div class="partyZong-title">网格党总支</div>
                <el-table
                    :data="tableData"
                    height="100%"
                    style="width:100%;font-size:1.125em;background:#1e3b62"
                    :row-style="{height:'1.875em'}"   
                    :header-cell-style="{background:'#1e3b62',color:'#fff',width:'100%',height:'0.375em',textAlign: 'center'}"
                    ref="rolltable"
                    :row-class-name="tableRowClassName"
                    @mouseenter.native="stopScroll"
                    @mouseleave.native="startScroll"
                >
                
                    <el-table-column
                        prop="select1689652520671label"
                        label="网格党总支名称"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.select1689652520671label||''}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="input1689652655816"
                        label="书记"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.input1689652655816||''}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="width:48%;height:95%;">
                <div class="partyZong-title">网格党支部</div>
                <el-table
                    :data="tableData1"
                    height="100%"
                    style="width:100%;font-size:1.125em;background:#1e3b62"
                    :row-style="{height:'1.875em'}"  
                    :header-cell-style="{background:'#1e3b62',color:'#fff',width:'100%',height:'2.375em',textAlign: 'center'}"
                    ref="rolltable1"
                    :row-class-name="tableRowClassName"
                    @mouseenter.native="stopScroll1"
                    @mouseleave.native="startScroll1"
                >
                    <el-table-column
                        prop="select1689652520671label"
                        label="网格支部名称"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.select1689652520671label||''}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="input1689652655816"
                        label="书记"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.input1689652655816||''}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    <div v-if="showXq" class="partyZong">
        <div class="revert" @click="toBack('showXq','showJg')">
            <i class="el-icon-arrow-left"></i>
            返回
        </div>
        <div style="display:flex;height:90%">
            <div style="width:100%;height:95%;">
                <div class="partyZong-title">小区党支部</div>
                <el-table
                    :data="tableData3"
                    height="100%"
                    style="width:100%;font-size:1.125em;background:#1e3b62"
                    :row-style="{height:'1.875em'}"   
                    :header-cell-style="{background:'#1e3b62',color:'#fff',width:'100%',height:'0.375em',textAlign: 'center'}"
                    ref="rolltable"
                    :row-class-name="tableRowClassName"
                    @mouseenter.native="stopScroll"
                    @mouseleave.native="startScroll"
                >
                
                    <el-table-column
                        prop="input1689739068795"
                        label="小区党支部名称"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.input1689739068795||''}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="input1689739082530"
                        label="书记"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.input1689739082530||''}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="input1689739085930"
                        label="电话"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{mobile(scope.row.input1689739085930)||''}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>

    <!-- 修改前上半部分 -->
    <!-- <div class="top">
        <div class="title">社区党委</div>
        <div class="organize-bigBox">
            <el-carousel :interval="5000" indicator-position="outside" height="1.12rem">
                <el-carousel-item v-for="item in list1" :key="item.id">
                    <div style="display:flex;flex-wrap:wrap;">
                        <div class="organize-box" v-for="data in item" :key="data.id">
                            <el-image
                                class="head-img"
                                :src="data.image_upload1688546377081&&data.image_upload1688546377081[0]&&data.image_upload1688546377081[0].url?data.image_upload1688546377081[0].url:require('../../src/assets/img/tou.png')"
                                fit="contain"
                                :preview-src-list="data.image_upload1688546377081.map(res=>res.url)"
                            >
                            </el-image>
                            <div class="info">
                                <div>
                                    <div>姓名：{{data.input1688546345189||'未知'}}</div>
                                    <div class="geyan">职务：{{data.input1688546357336||'暂无'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item v-for="item in list2" :key="item.id">
                    <div style="display:flex;flex-wrap:wrap;">
                        <div class="organize-box organize-box2" v-for="data in item" :key="data.id">
                            <el-image
                                class="head-img"
                                :src="data.image_upload1688546377081&&data.image_upload1688546377081[0]&&data.image_upload1688546377081[0].url?data.image_upload1688546377081[0].url:require('../../src/assets/img/tou.png')"
                                fit="contain"
                                :preview-src-list="data.image_upload1688546377081.map(res=>res.url)"
                            >
                            </el-image>
                            <div class="info">
                               <div>
                                    <div>姓名：{{data.input1688546345189?(data.input1688546345189+'（兼职委员）'):'未知'}}</div>
                                    <div class="geyan">职务：{{data.input1688546357336||'暂无'}}</div>
                               </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div> -->
      <!-- <div class="title">兼职委员</div> -->
        <!-- 两个个人信息 -->
        <!-- <div class="organize-bigBox">
            <el-carousel :interval="5000" indicator-position="outside" height="0.545rem">
                <el-carousel-item v-for="item in list2" :key="item.id">
                    <div style="display:flex;flex-wrap:wrap;">
                        <div class="organize-box organize-box2" v-for="data in item" :key="data.id">
                            <el-image
                                class="head-img"
                                :src="data.image_upload1688546377081&&data.image_upload1688546377081[0]&&data.image_upload1688546377081[0].url?data.image_upload1688546377081[0].url:require('../../src/assets/img/tou.png')"
                                fit="contain"
                                :preview-src-list="data.image_upload1688546377081.map(res=>res.url)"
                            >
                            </el-image>
                            <div class="info">
                               <div>
                                    <div>姓名：{{data.input1688546345189||'未知'}}</div>
                                    <div class="geyan">职务：{{data.input1688546357336||'暂无'}}</div>
                               </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div> -->
    <!-- <div class="bottom">
        <el-image
            class="img"
            :src="require('../../src/assets/img/party/jg.png')"
            fit="contain"
            :preview-src-list="['https://smartserver-file.oss-cn-chengdu.aliyuncs.com/shehong/image33.png']"
        >
        </el-image>
    </div> -->
    
  </div>
</template>

<script>
import {getVillage,getJiaGou} from '../../src/api/api'
import SeeksRelationGraph from 'relation-graph'
import {secrecyMobile}from '../../src/utils/util'
let rolltimer
let rolltimer1
export default {
    name:"organize",
    components: { SeeksRelationGraph },
    data(){
        return{
            list1:[],
            list2:[],
            loading:false,
            showJg:true,
            showDw:false,
            showZz:false,
            showXq:false,
            tableData:[],
            tableData1:[],
            tableData3:[],
            jiaGouData:{},
            graphOptions: {
                // debug: true,
                // 禁用拖拽
                disableDragNode: true,
                // backgrounImage: 'http://ai-mark.cn/images/ai-mark-desc.png',
                backgrounImageNoRepeat: true,
                layouts: [
                {
                    label: '中心',
                    layoutName: 'tree',
                    layoutClassName: 'seeks-layout-center',
                    defaultJunctionPoint: 'border',
                    defaultNodeShape: 0,
                    defaultLineShape: 1,
                    // 通过这4个属性来调整 tree-层级距离&节点距离
                    min_per_width: '280',
                    max_per_width: '500',
                    min_per_height: '160',
                    max_per_height: '900',
                    // 如果此选项有值，则优先级高于上面那4个选项
                    levelDistance: '',
                },
                ],
                // defaultExpandHolderPosition: 'bottom',
                defaultNodeShape: 1,
                // defaultNodeWidth: '100', // 节点宽度
                defaultLineShape: 4, //线条样式
                defaultJunctionPoint: 'lr',
                defaultNodeBorderWidth: 0,
                defaultLineColor: '#cc1a0d',
                defaultNodeColor: '#cc1a0d',
                allowShowMiniToolBar:false,
                defaultLineWidth:2
            },
        }
    },
    created () {
        this.getJiaGouData()
        this.getData()
        setTimeout(this.getData2(),500)
    },
    mounted() {
    },
    destroyed(){
    },
    methods: {
        getJiaGouData(){
            getJiaGou().then(res=>{
                // console.log(res,'架构')
                this.jiaGouData=res.data.data
                this.showSeeksGraph()
            })
        },
        showSeeksGraph() {
            var __graph_json_data = {
                rootId: 'a',
                nodes: [
                    { id: '1', text: '社区党委',
                        html:this.getHtml('a','社区党委')
                    },
                    { id: '2', text: '网格总支', 
                        html:this.getHtml('c',{
                            title:'网格总支(支部)',
                            content:`(${this.jiaGouData.zongZhi||0}个总支${this.jiaGouData.zhiBu||0}个支部)`,
                        }),
                    },
                     { id: '3', 
                        html:this.getHtml('b', {
                            title:'功能型党支部',
                            img:require('@/assets/img/personal/li.png'),
                            content1:'射洪市人才公寓功能型党支部',
                            content2:'书记：李二强  18202817798'
                        }), 
                    },
                    { id: '4', 
                        html: this.getHtml('b', {
                            title:'非公党支部',
                            img:require('@/assets/img/personal/xie.png'),
                            content1:'蟠龙社区第一联合党支部',
                            content2:'书记：谢龙庆 15828988189'
                        }),
                    },
                    { id: '5', text: '小区党支部',html:this.getHtml('d',`小区党支部 (${this.jiaGouData.community||0}个)`)},
                ],
                lines: [
                    { from: '1', to: '2'},
                    { from: '2', to: '3'},
                    { from: '1', to: '4'},
                    { from: '2', to: '5'},
                    
                ]
            }
            this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (seeksRGGraph) => {
                // Called when the relation-graph is completed 
            })
        },
        onNodeClick(nodeObject, $event) {
            // console.log('onNodeClick:', nodeObject)
            if(nodeObject.text=="社区党委"){
                this.showJg=false
                this.showDw=true
            }else if(nodeObject.text=="网格总支"){
                this.showJg=false
                this.showZz=true
                this.getPartyData("网格党总支")   
                this.getPartyData("网格党支部") 
            }else if(nodeObject.text=="小区党支部"){
                this.showJg=false
                this.showXq=true
                this.getData3()   
            }
        },
        toBack(now,backTo){
            this[now]=false
            this[backTo]=true
        },
        getHtml(type,name){
            if(type=='a'){
                return ` <div  style="background-color:#cc1a0d;color:#fff;border-radius: 5px;padding:0 10px;width:160px;height:60px;line-height:60px;text-align:center;${name!='楼栋长(136人)'?'cursor: pointer;':''}font-size:16px">
                    ${name}
                </div>`
            }else if(type=='b'){
                return `<div style="background-color:#cc1a0d;color:#fff;border-radius: 5px;padding:5px 10px;font-size:16px">
                    <div style="text-align:center;font-weight: 700;">${name.title}</div>
                    <div style="display: flex;justify-content: center;">
                        <img src="${name.img}" style="width:100px;height:120px;" object="fit"/>
                    </div>
                    <div style="font-size:0.875em;">${name.content1}</div>
                    <div style="font-size:0.875em;">${name.content2}</div>
                </div>`
            }else if(type=='c'){
                return  `<div  style="background-color: #cc1a0d;color:#fff;border-radius: 5px;padding:5px 10px;width:160px;cursor: pointer;font-size:16px">
                            <div style="text-align:center;font-weight: 700;">${name.title}</div>
                            <div style="text-align:center;">${name.content}</div>
                        </div>`
            }else if(type=='d'){
                return ` <div  style="background-color: #cc1a0d;color:#fff;border-radius: 5px;padding:0 10px;width:200px;height:100px;line-height:100px;text-align:center;${name!='楼栋长(136人)'?'cursor: pointer;':''}font-size:16px">
                    ${name}
                </div>`
            }else if(type=='e'){
                return ` <div  style="background-color:#cc1a0d;color:#fff;border-radius: 5px;padding:0 10px;width:200px;height:60px;line-height:60px;text-align:center;${name!='楼栋长(136人)'?'cursor: pointer;':''}font-size:16px">
                    ${name}
                </div>`
            }
            
        },
        getData(){
            getVillage({
                formKey: "LwA72VvB",
                queryId: "104",
                queryParams: {
                select1688546247197label: "社区党委班子"
                },
                size: 100,
                current: 0
            }).then(res=>{
                this.loading=false
                this.list1=res.data.data.rows
                // let array=res.data.data.rows
                // let list=[]
                // let arr=[]
                // for (let index = 0; index < array.length; index++) {
                //     if(array.length<6){
                //         arr.push(array[index])
                //         list.push(arr)
                //         return 
                //     }else{
                //         if((index+1)%6==0){
                //             arr.push(array[index])
                //             list.push(arr)
                //             arr=[]
                //         }else{
                //             arr.push(array[index])
                //         } 
                //     }
                   
                // }
                // if( arr.length>0){
                //     list.push(arr)
                // }
                // this.list1=list
                // console.log(res,'先锋岗')
                // console.log(list,'先锋岗')
            })
        },
        getData2(){
            getVillage({
                formKey: "LwA72VvB",
                queryId: "104",
                queryParams: {
                select1688546247197label: "兼职委员"
                },
                size: 100,
                current: 0
            }).then(res=>{
                this.loading=false
                this.list2=res.data.data.rows
                // let array=res.data.data.rows
                // let list=[]
                // let arr=[]
                // for (let index = 0; index < array.length; index++) {
                //     if(array.length<4){
                //         arr.push(array[index])
                //         list.push(arr)
                //     }else{
                //         if((index+1)%4==0){
                //             arr.push(array[index])
                //             list.push(arr)
                //             arr=[]
                //         }else{
                //             arr.push(array[index])
                //         } 
                //     }
                // }
                // if( arr.length>0){
                //     list.push(arr)
                // }
                // this.list2=list
                // console.log(list,'兼职委员3')
                // console.log(res,'兼职委员')
            })
        },
        getData3(){
            getVillage({
                formKey: "XlO9p5uh",
                queryId: "114",
                queryParams: {
                    select1689746875437label: "蟠龙社区"
                },
                size: 100,
                current: 0
            }).then(res=>{
                // console.log(res,'tableData3')
                 this.tableData3=res.data.data.rows||[]
            })
        },
        getPartyData(name){
            getVillage({
                formKey: "cILcu5hB",
                queryId: "111",
                queryParams: {
                    select1689652520671label: name
                },
                size: 100,
                current: 0
            }).then(res=>{
                if(name=="网格党总支"){
                    this.tableData=res.data.data.rows||[]
                    this.tableScroll(false)
                }
                if(name=='网格党支部'){
                    this.tableData1=res.data.data.rows||[]
                    this.tableScroll1(false)
                }
                // console.log(res)
            })
        },
        mobile(phone){
            return secrecyMobile(phone)
        },
        startScroll() {
            this.tableScroll(false);
        },
        stopScroll() {
            this.tableScroll(true);
        },
            startScroll1() {
            this.tableScroll1(false);
        },
        stopScroll1() {
            this.tableScroll1(true);
        },
        //滚动方法
        tableScroll(stop) {
            if (stop) {
                clearInterval(rolltimer)
                return
            }
            const table = this.$refs.rolltable
            const divData = table.bodyWrapper
            rolltimer = setInterval(() => {
                divData.scrollTop += 1
                if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                    if (table.tableData.length >=9) {
                        divData.scrollTop = 0
                    }
                }
            }, 35)
        },
        tableScroll1(stop) {
            if (stop) {
                clearInterval(rolltimer1)
                return
            }
            const table1 = this.$refs.rolltable1
            const divData1 = table1.bodyWrapper
            rolltimer1 = setInterval(() => {
                divData1.scrollTop += 1
                if (divData1.clientHeight + divData1.scrollTop + 1 > divData1.scrollHeight) {
                    if (table1.tableData.length >=9) {
                        divData1.scrollTop = 0
                    }
                }
            }, 35)
        },
        tableRowClassName({row, rowIndex,columnIndex}) {
            // console.log(row,'行')
            if (rowIndex%2==0) {
                return 'singular';
            }
            if (rowIndex%2==1) {
                return 'dual';
            }
            return '';
        }
        
    }

}
</script>

<style scoped>
.partyZong{
    height:100%;
    padding:10px 15px;
}
.partyZong-title{
    font-size: 16px;
    color: #000;
    /* padding-left:27px; */
    padding-bottom: 10px;
    font-weight: bold;
    text-align: center;
}
.revert{
    display: inline-block;
    height:30px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}
.back{
    position: absolute;
    left:0;
    top:50%;
    transform: translate(0,-50%);
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}
.organize-page{
    width: 100%;
    height:100%;
    background: #F7F8FA;
    border-radius: 4px;
    overflow: scroll;
}
.title{
    font-size: 18px;
    font-weight: bold;
    color: #191919;
    padding:10px 0;
    position: relative;
    text-align: center;
}
.top{
    padding:0 15px;
    margin-bottom: 20px;
}
.organize-bigBox{
    width:100%;
    height:250px;
}
.organize-page .organize-box{
    display: flex;
    width:240px;
    height:104px;
    margin-right:10px;
    align-items: center;
    margin-bottom: 20px;
}
.organize-page .organize-box2{
    display: flex;
    width:240px;
    height:104px;
    margin-right:10px;
    align-items: center;
    margin-bottom: 20px;
}
.head-img{
    width:92.5px;
    height:104px;
    flex-shrink: 0;
}
.info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    background: #4D6A91;
    height:100%;
    padding:12px 12.5px 12.5px 12px;
    line-height: 23px;
}
.organize-page .organize-box2{
    width:365px;
    margin-right:10px;
}
.organize-page .organize-box:nth-of-type(3n){
    margin-right:0;
}
.organize-page .organize-box2:nth-of-type(2n){
    margin-right:0;
}
.organize-page .img{
    width:100%;
    height:400px;
}
.organize-page .text{
    width:100%;
    text-align: center;
    font-size: 18px;
    color: #031d3b;
}
.bottom{
    padding:0 10px;
}
</style>