<template>
    <el-table
        :data="tableData"
        height="100%"
        style="width:100%;font-size:0.875em"
        :row-style="{width:'100%',height:'1.875em'}"   
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#102e6d',color:'#fff',height:'1.875em',}"
        ref="rolltable"
        @mouseenter.native="stopScroll"
        @mouseleave.native="startScroll"
    >
         <el-table-column
            prop="title"
            label="网格"
            :width="width*1.5"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.input1686711092757||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="小区"
            :width="width*1.5"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.input1677569925469||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="楼栋"
            :width="width"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.number1686019234924||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="户数"
            :width="width"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.number1686020199623||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="人口数"
            :width="width"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.number1686020177458||''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="title"
            label="常住"
            :width="width"
        >
            <template slot-scope="scope">
                <div>
                    {{scope.row.number1686020199623||''}}
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import {getVillage} from '../../src/api/api'
let rolltimer
export default {
        name:"left1_2",
        data() {
            return {
                tableData: [],
                width:40,
            }
        },
        created(){
            this.getData()
        },
        mounted(){
          this.tableScroll(false)
            this.$nextTick(() => {
                // console.log(this.$refs.rolltable.$el.offsetWidth,'宽度')
                this.width=this.$refs.rolltable.$el.offsetWidth/7
            })

        },
        destroyed(){
 
        },
        methods: {
            getData(){
                getVillage({
                    current: 0,
                    formKey:"t362qQ2O",
                    queryId:"73",
                    queryParams: {
                        select1677570492939label:"蟠龙社区",
                        select1677569952195label:"小区", 
                    },
                    size: 100
                }).then(res=>{
                    // console.log(res,'小区概况')
                    this.tableData=res.data.data.rows
                })
            },
             startScroll() {
                this.tableScroll(false);
            },

            stopScroll() {
                this.tableScroll(true);
            },
            //滚动方法
            tableScroll(stop) {
                if (stop) {
                    clearInterval(rolltimer)
                    return
                }
                const table = this.$refs.rolltable
                const divData = table.bodyWrapper
                rolltimer = setInterval(() => {
                    divData.scrollTop += 1
                    if (divData.clientHeight + divData.scrollTop + 1 > divData.scrollHeight) {
                        if (table.tableData.length >=3) {
                            divData.scrollTop = 0
                        }
                    }
                }, 35)
            },
            tableRowClassName({row, rowIndex}) {
                if (row.Flag == 'Y') {
                    return 'changeBcg'
                }
                if (rowIndex%2==0) {
                return 'success-row';
                } else if (rowIndex%2==1) {
                return 'warning-row';
                }
                return '';
            }
        }
}
</script>
<style>
.el-table{
    background: transparent;
}
.el-table .warning-row {
background: #102e6d;
color: #ffffff;
}

.el-table .success-row {
background: #02082a;
color: #ffffff;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom:0;
}
 /* 去掉表格单元格边框 */
.el-table th{
    border:none;
  }
.el-table td,.el-table th.is-leaf {
  border:none;
}
 /* 表格最外边框 */
.el-table--border, .el-table--group{
  border: none;
}
 /* 头部边框 */
.el-table thead tr th.is-leaf{
  border-bottom: none;
  border-right: none;
}
/* 表格最外层边框-底部边框 */
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.el-table::before{
  width: 0;
}
.el-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
/* 表格有滚动时表格头边框 */
/* .el-table--border th.gutter:last-of-type {
    border: 1px solid #EBEEF5;  
    border-left: none;
} */
.el-table--border::after, .el-table--group::after, .el-table::before{
    background-color: #02082A !important;
}
.el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #6495ED !important;
}
.current-row > td {
   background: #6495ED !important;
}
::v-deep .is-scrolling-none {
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
}
::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}
::v-deep .el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}
.el-table--scrollable-x .el-table__body-wrapper {
overflow-x: hidden;
}
</style>